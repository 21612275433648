// @flow

import React, { Component } from "react";
import { isEqual, noop, get } from "lodash";
import { Link } from "react-router-dom";
import type { NullaryFn, UnaryFn, TernaryFn } from "@tvg/types/Functional";
import type { RacePanelLink } from "@tvg/types/Race";
import type { UserOptedInPromos } from "@tvg/types/User";
import buildRaceUrl from "@tvg/formatter/url";
import {
  handleTouchMove,
  handleTouchStart
} from "@tvg/utils/swipeEventHandlers";

import {
  Title,
  UpperSection,
  ListContainer,
  IconContainer,
  LinkContainer,
  UpcomingRacesWrapper,
  UpcomingContainer
} from "./styled-components";
import RaceInfoPanel from "../../_molecule/RaceInfoPanel";
import Icon from "../../_static/Icons";
import { arrowForward } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import EmptyStateMessages, {
  type EmptyMessages
} from "../../_molecule/EmptyStateMessages";

type Props = {
  /**
   * Array of races
   */
  races: RacePanelLink[],
  /**
   * Callback when the race in Track is clicked
   */
  onRaceClick: TernaryFn<
    RacePanelLink,
    string,
    string | UserOptedInPromos,
    void
  >,
  /**
   * Callback when the upcoming races container is being scrolled by swiping
   */
  onContainerSwipe: UnaryFn<number, void>,
  /**
   * Whenever the component is loading
   */
  isLoading: boolean,
  /**
   * Enables the promo badge on race link
   */
  hasPromoBadge: boolean,
  /**
   * Object with the user opted-in promos
   */
  optedInPromos: UserOptedInPromos,
  /**
   * The device this is displaying on
   */
  device: "mobile" | "tablet",
  /**
   * Title for upper section
   */
  title: string,
  /**
   * Flag to see if the "All Races" button should be rendered
   */
  renderAllRacesBtn: boolean,
  /**
   * Aims to flag the empty messages rendering
   */
  shouldRenderEmptyMessages: boolean,
  /**
   * Aims to flag the error empty message
   */
  hasError: boolean,
  /**
   * Callback to when clear filters button is clicked
   */
  onClearFilters: NullaryFn<void>,
  /**
   * Checks if there are active filters set in tracks header
   */
  hasActiveFilters: boolean,
  emptyMessages: EmptyMessages,
  isMTPNewRules: boolean,
  useIsPromoTagShownFlag: boolean
};

export default class UpcomingRaces extends Component<Props> {
  static defaultProps = {
    races: [],
    onRaceClick: noop,
    onContainerSwipe: noop,
    isLoading: false,
    hasPromoBadge: false,
    optedInPromos: {},
    device: "mobile",
    title: "Upcoming Races",
    renderAllRacesBtn: true,
    shouldRenderEmptyMessages: false,
    hasError: false,
    onClearFilters: noop,
    hasActiveFilters: false,
    emptyMessages: {},
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.races.length !== nextProps.races.length ||
      !isEqual(this.props, nextProps)
    );
  }

  evaluateReachingEdge = (e: SyntheticTouchEvent<*>) => {
    const scrollableElement = e.currentTarget;
    let edgeHasBeenReached = false;
    if (scrollableElement) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollableElement;
      edgeHasBeenReached =
        scrollLeft === 0 || scrollLeft + clientWidth === scrollWidth;
    }

    return edgeHasBeenReached;
  };

  handleSwipeGTMEvents = (e: SyntheticTouchEvent<*>) => {
    let swipeDirection = handleTouchMove(e);
    const isLivingOnTheEdge = this.evaluateReachingEdge(e);

    swipeDirection = isLivingOnTheEdge ? 0 : swipeDirection;
    if (swipeDirection !== 0) {
      this.props.onContainerSwipe(swipeDirection);
    }
  };

  renderRacesList = () =>
    this.props.races.map((race) => (
      <UpcomingContainer
        isFeatured={get(race, "highlighted.style", false) === "Highlighted"}
        data-qa-label={`upcomingRaces-${race.raceId}`}
        key={`upcomingRaces-${race.raceId}`}
      >
        <RaceInfoPanel
          race={race}
          raceCardUrl={buildRaceUrl(
            race.trackCode,
            race.trackName,
            +race.raceNumber
          )}
          onCardClick={this.props.onRaceClick}
          optedInPromos={this.props.optedInPromos}
          context="upcomingRaces"
          isMTPNewRules={this.props.isMTPNewRules}
          useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
        />
      </UpcomingContainer>
    ));

  renderMessageType = () => {
    if (this.props.hasError) {
      return "error";
    }
    if (this.props.races.length === 0 && !this.props.hasActiveFilters) {
      return "noraces";
    }
    return "info";
  };

  render() {
    const isEmpty =
      this.props.shouldRenderEmptyMessages &&
      !this.props.renderAllRacesBtn &&
      !this.props.races.length;
    return (
      <UpcomingRacesWrapper data-qa-label="upcomingRacesContainer">
        <UpperSection data-qa-label="upcomingRaces-top">
          <Title>{this.props.title}</Title>
        </UpperSection>
        <ListContainer
          isEmpty={isEmpty}
          data-qa-label="upcomingRacesListContainer"
          onTouchStart={(e: SyntheticTouchEvent<*>) => handleTouchStart(e)}
          onTouchMove={(e: SyntheticTouchEvent<*>) =>
            this.handleSwipeGTMEvents(e)
          }
        >
          {this.renderRacesList()}
          {this.props.renderAllRacesBtn && (
            <LinkContainer data-qa-label="upcomingLinkContainer">
              <Link to="/tracks" data-qa-label="upcomingLinkContainerLink">
                <IconContainer data-qa-label="upcomingLinkContainerIconContainer">
                  <Icon
                    icon={arrowForward}
                    color={buildColor("blue_accent", "500")}
                    size={16}
                    qaLabel="upcomingLinkContainerIcon"
                  />
                </IconContainer>
                SEE ALL RACES
              </Link>
            </LinkContainer>
          )}
          {isEmpty && (
            <EmptyStateMessages
              messageType={this.renderMessageType()}
              componentName="upcoming races"
              onClearFilters={this.props.onClearFilters}
              emptyMessages={this.props.emptyMessages}
            />
          )}
        </ListContainer>
      </UpcomingRacesWrapper>
    );
  }
}
