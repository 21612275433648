// @flow

import React, { PureComponent, Fragment } from "react";
import { noop } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";

import MessageBox from "../../_molecule/MessageBox";
import Button from "../../_atom/Buttons/button";
import TabGroup from "../../_molecule/TabGroup";
import AlertLine from "../../_molecule/AlertLine";
import ProcessingOverlay from "../../_static/ProcessingOverlay";

import {
  AlertsContainer,
  ButtonContainer,
  AlertsList
} from "./styled-components";

export type TalentAlert = {
  pictureUrl: string,
  id: string,
  talentName: string,
  isActive: boolean,
  isNew: boolean,
  onClick: UnaryFn<boolean, mixed>
};

export type TrackAlert = {
  trackName: string,
  id: string,
  isActive: boolean,
  isNew: boolean,
  onClick: UnaryFn<boolean, mixed>
};

export type SelectedTab = "talent" | "track";

type Props = {
  /**
   * list of talents alerts
   */
  talentsList: TalentAlert[],
  /**
   * list of tracks alerts
   */
  tracksList: TrackAlert[],
  /**
   * Witch tab should be rendered: talents alerts or tracks alerts
   */
  selectedTab: SelectedTab,
  /**
   * callback for confirm button
   */
  onConfirm: NullaryFn<void>,
  /**
   * callback for cancel button
   */
  onCancel: NullaryFn<void>,
  /**
   * callback for clicking on tab
   */
  handleTabClick: UnaryFn<SelectedTab, void>,
  /**
   * info message for talents pick tab
   */
  talentAlertsInfoMessage: string,
  /**
   * info message for tracks pick tab
   */
  trackAlertsInfoMessage: string,
  /**
   * enable tab with list of tracks
   */
  enablePicksTracksTabAlerts: boolean,
  /**
   * if component is loading
   */
  isLoading: boolean
};

export default class PicksAlerts extends PureComponent<Props> {
  static defaultProps = {
    talentsList: [],
    tracksList: [],
    enablePicksTracksTabAlerts: false,
    isLoading: false,
    selectedTab: "talent",
    onConfirm: noop,
    onCancel: noop,
    handleTabClick: noop,
    talentAlertsInfoMessage:
      "Get notified when your favorite talent has a new pick available to bet",
    trackAlertsInfoMessage:
      "Get notified when TVG picks are submitted on your favorite tracks"
  };

  renderTabs = () => {
    const tabs = [
      {
        title: "talent alerts",
        isActive: this.props.selectedTab === "talent",
        onClick: () => this.props.handleTabClick("talent")
      },
      {
        title: "track alerts",
        isActive: this.props.selectedTab === "track",
        onClick: () => this.props.handleTabClick("track")
      }
    ];
    return <TabGroup tabs={tabs} />;
  };

  renderInfoMessage = () => (
    <MessageBox
      type="info"
      message={
        this.props.selectedTab === "talent"
          ? this.props.talentAlertsInfoMessage
          : this.props.trackAlertsInfoMessage
      }
    />
  );

  renderTalentsAlerts = () =>
    this.props.talentsList.map((talentAlert: TalentAlert) => (
      <li key={talentAlert.id}>
        <AlertLine
          qaLabel={`talentAlert-${talentAlert.talentName}`}
          pictureUrl={talentAlert.pictureUrl}
          title={talentAlert.talentName}
          isActive={talentAlert.isActive}
          onToggleClick={talentAlert.onClick}
        />
      </li>
    ));

  renderTracksAlerts = () =>
    this.props.tracksList.map((trackAlert: TrackAlert) => (
      <li key={trackAlert.id}>
        <AlertLine
          qaLabel={`trackAlert-${trackAlert.trackName}`}
          title={trackAlert.trackName}
          isActive={trackAlert.isActive}
          onToggleClick={trackAlert.onClick}
        />
      </li>
    ));

  renderBottomButtons = () => (
    <ButtonContainer>
      <Button
        qaLabel="alerts-cancel"
        type="tertiary"
        size="big"
        onClick={this.props.onCancel}
      >
        cancel
      </Button>
      <Button size="big" qaLabel="alerts-save" onClick={this.props.onConfirm}>
        confirm alerts
      </Button>
    </ButtonContainer>
  );

  render() {
    return (
      <Fragment>
        {this.props.isLoading && <ProcessingOverlay customTop={48} />}
        <AlertsContainer>
          {this.props.enablePicksTracksTabAlerts && this.renderTabs()}
          {this.renderInfoMessage()}
          <AlertsList>
            {this.props.selectedTab === "talent"
              ? this.renderTalentsAlerts()
              : this.renderTracksAlerts()}
          </AlertsList>
          {this.renderBottomButtons()}
        </AlertsContainer>
      </Fragment>
    );
  }
}
