// @flow
import type { OperationComponent } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import { get } from "lodash";
import type { WagerProfile } from "@tvg/types/User";

type RaceFilter = {
  trackCode: string,
  number: string
};

type ApolloProps = {
  wagerProfile: WagerProfile,
  matchRaces: RaceFilter[]
};

const conf = tvgConf();

const POLL_INTERVAL = 60000;

export default {
  skip: (props: ApolloProps) => !props.matchRaces.length,
  options: (props: ApolloProps) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      matchRaces: get(props, "matchRaces", []),
      isOpen: true,
      isGreyhounds: false,
      ...conf.graphContext()
    };

    return {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({
    races: get(result, "data.races", []),
    refetch: (variables: mixed) => result.data.refetch(variables)
  })
};
