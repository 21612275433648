import React, { memo } from "react";
import { IconButton, useColorTokens } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Props } from "./types";
import { Container } from "./styled-components";
import {
  CONTAINER,
  FAVORITE,
  ICON,
  NON_FAVORITE,
  SUMMARY
} from "../../utils/constants";

const FavoriteIcon = ({
  isFavorite,
  toggleFavorite,
  trackName = "",
  qaLabel = "",
  className
}: Props) => {
  const { component } = useColorTokens();

  return (
    <Container
      className={className}
      isFavorite={isFavorite}
      data-qa-label={buildQaLabel([
        qaLabel,
        SUMMARY,
        ICON,
        CONTAINER,
        trackName
      ])}
    >
      <IconButton
        qaLabel={buildQaLabel([
          qaLabel,
          SUMMARY,
          ICON,
          trackName,
          isFavorite ? FAVORITE : NON_FAVORITE
        ])}
        onPress={toggleFavorite}
        variant="tertiary"
        tertiaryColor={component.button.tertiary.content.base}
        size="s"
        iconSize="s"
        iconName="star"
      />
    </Container>
  );
};

export default memo(FavoriteIcon);
