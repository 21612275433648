// @flow

import React, { PureComponent, Fragment } from "react";
import { get, noop } from "lodash";
import type { RacePanelLink } from "@tvg/types/Race";
import type { TernaryFn, QuadFn } from "@tvg/types/Functional";
import type { UserOptedInPromos } from "@tvg/types/User";
import {
  Container,
  Footer,
  FooterWrapper,
  RunnerNumber,
  RacecardLink,
  TopRaceRunnerList,
  RacecardLinkTVG4
} from "./styled-components";
import RaceInfoPanel from "../../_molecule/RaceInfoPanel";
import RunnerList from "../RunnerList";

type Props = {
  race: RacePanelLink,
  isDesktop: boolean,
  onCardClick: TernaryFn<
    RacePanelLink,
    string,
    string | UserOptedInPromos,
    void
  >,
  raceCardUrl: string,
  onRunnerSelection: QuadFn<
    RacePanelLink,
    number,
    string,
    ?SyntheticKeyboardEvent<*>,
    void
  >,
  optedInPromos: UserOptedInPromos,
  isMTPNewRules: boolean,
  useIsPromoTagShownFlag: boolean
};

export default class TopRace extends PureComponent<Props> {
  static defaultProps = {
    race: {},
    raceCardUrl: "",
    onCardClick: noop,
    onRunnerSelection: noop,
    optedInPromos: {},
    isDesktop: false,
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  renderFooter = () => (
    <Footer data-qa-label="top-race-footer">
      <FooterWrapper>
        <RunnerNumber data-qa-label="top-race-runnerNumber">
          {this.props.race.runnerNumber} Runners
        </RunnerNumber>
        {!this.props.isDesktop ? (
          <RacecardLink
            data-qa-label="top-race-fullRacecardUrl"
            onClick={() =>
              this.props.onCardClick(
                this.props.race,
                this.props.raceCardUrl,
                "Footer"
              )
            }
            to={this.props.raceCardUrl}
          >
            Full racecard
          </RacecardLink>
        ) : (
          <RacecardLinkTVG4
            data-qa-label="top-race-fullRacecardUrl"
            onClick={() =>
              this.props.onCardClick(
                this.props.race,
                this.props.raceCardUrl,
                "Footer"
              )
            }
          >
            Full racecard
          </RacecardLinkTVG4>
        )}
      </FooterWrapper>
    </Footer>
  );

  renderTopRace = () => (
    <Fragment>
      <RaceInfoPanel
        race={this.props.race}
        raceCardUrl={this.props.raceCardUrl}
        onCardClick={this.props.onCardClick}
        optedInPromos={this.props.optedInPromos}
        isDesktop={this.props.isDesktop}
        isMTPNewRules={this.props.isMTPNewRules}
        useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
      />
      <TopRaceRunnerList data-qa-label="runner-list-table">
        <RunnerList
          data-qa-label="top-race-runnerList"
          race={this.props.race}
          numColumns={1}
          raceTypeCode={get(this.props, "race.type.code", "T")}
          raceUrl={this.props.raceCardUrl}
          hasTrackMasterInfo={false}
          runners={this.props.race.bettingInterests}
          onRunnerSelection={this.props.onRunnerSelection}
          redirectToRaceCard
          hasHover
          showRunnerLineActiveState={false}
        />
      </TopRaceRunnerList>
      {this.renderFooter()}
    </Fragment>
  );

  render() {
    return (
      <Container
        isDesktop={this.props.isDesktop}
        data-qa-label="top-race-container"
      >
        {this.renderTopRace()}
      </Container>
    );
  }
}
