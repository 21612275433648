// @flow
import React, { Component, Fragment } from "react";
import { get, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { UnaryFn } from "@tvg/types/Functional";
import type { TopPool } from "@tvg/types/Pool";
import {
  handleTouchMove,
  handleTouchStart,
  evaluateReachingEdge
} from "@tvg/utils/swipeEventHandlers";
import {
  Wrapper,
  Title,
  ListContainer,
  PoolContainer,
  SubTitle
} from "./styled-components";
import PoolInfoPanel from "../../_molecule/PoolInfoPanel";
import TopPoolsMask from "../../_static/Masks/topPools";

type Props = {
  /**
   * List of Top Pools
   */
  pools: TopPool[],
  /**
   * Whenever the component is loading
   */
  isLoading: boolean,
  /**
   * The device this is displaying on
   */
  device: Device,
  /**
   * Title for upper section
   */
  title: string,
  /**
   * Loading Mask for first request
   */
  hasRequested: boolean,
  /**
   * Event click on pool
   */
  onPoolClick: UnaryFn<TopPool, void>,
  /*
   * Aims to send a gtm event on scrolling
   */
  sendGTMScrollEvent: UnaryFn<boolean, void>
};

const getSeparetedPools = (pools: TopPool[]) => {
  const highligthedPools = [];
  const topPools = [];

  if (Array.isArray(pools) && pools.length) {
    pools.forEach((pool) => {
      if (get(pool, "race.highlighted.pinnedOrder", null) === null) {
        topPools.push(pool);
      } else {
        highligthedPools.push(pool);
      }
    });
  }

  return {
    highligthed: highligthedPools,
    comingup: topPools
  };
};

export default class TopPools extends Component<Props> {
  static defaultProps = {
    pools: [],
    isLoading: false,
    device: "mobile",
    title: "Top Pools",
    hasRequested: false,
    onPoolClick: noop,
    sendGTMScrollEvent: noop
  };

  handleSwipeGTMEvents = (e: SyntheticTouchEvent<*>) => {
    let swipeDirection = handleTouchMove(e);
    const isLivingOnTheEdge = evaluateReachingEdge(e);
    swipeDirection = isLivingOnTheEdge ? 0 : swipeDirection;
    if (swipeDirection !== 0) {
      this.props.sendGTMScrollEvent(swipeDirection < 0);
    }
  };

  renderMobile = () => {
    const topPools = this.props.pools.slice(0, 10);

    if (Array.isArray(topPools) && topPools.length) {
      return (
        <Fragment>
          {topPools.map((pool) => (
            <PoolContainer
              data-qa-label={`pool-${pool.trackCode}-${get(
                pool,
                "race.raceNumber"
              )}`}
              key={`pool-${pool.trackCode}-${get(
                pool,
                "race.raceNumber"
              )}-${get(pool, "amount")}`}
              onClick={() => this.props.onPoolClick(pool)}
            >
              <PoolInfoPanel pool={pool} />
            </PoolContainer>
          ))}
        </Fragment>
      );
    }

    return null;
  };

  renderDesktop = () => {
    const pools = getSeparetedPools(this.props.pools);

    if (pools.highligthed.length || pools.comingup.length) {
      return (
        <Fragment>
          {!!pools.highligthed.length && (
            <Fragment>
              <SubTitle data-qa-label="topPoolsSubTitle-highlighted">
                TODAY’S HIGHLIGHTS
              </SubTitle>
              {pools.highligthed.slice(0, 6).map((pool) => (
                <PoolContainer
                  data-qa-label={`pool-${pool.trackCode}-${get(
                    pool,
                    "race.raceNumber"
                  )}`}
                  key={`pool-${pool.trackCode}-${get(
                    pool,
                    "race.raceNumber"
                  )}-${get(pool, "amount")}`}
                  isDesktop
                  onClick={() => this.props.onPoolClick(pool)}
                >
                  <PoolInfoPanel isDesktop pool={pool} />
                </PoolContainer>
              ))}
            </Fragment>
          )}
          {pools.highligthed.length < 6 && !!pools.comingup.length && (
            <Fragment>
              {!!pools.highligthed.length && (
                <SubTitle data-qa-label="topPoolsSubTitle-comingup">
                  COMING UP
                </SubTitle>
              )}
              {pools.comingup
                .slice(0, 6 - pools.highligthed.length)
                .map((pool) => (
                  <PoolContainer
                    data-qa-label={`pool-${pool.trackCode}-${get(
                      pool,
                      "race.raceNumber"
                    )}`}
                    key={`pool-${pool.trackCode}-${get(
                      pool,
                      "race.raceNumber"
                    )}-${get(pool, "amount")}`}
                    isDesktop
                    onClick={() => this.props.onPoolClick(pool)}
                  >
                    <PoolInfoPanel isDesktop pool={pool} />
                  </PoolContainer>
                ))}
            </Fragment>
          )}
        </Fragment>
      );
    }

    return null;
  };

  render() {
    const isDesktop = this.props.device === "desktop";
    return this.props.isLoading && !this.props.hasRequested ? (
      <TopPoolsMask isDesktop={isDesktop} />
    ) : (
      get(this.props, "pools.length") > 0 && (
        <Wrapper data-qa-label="topPoolsContainer" isDesktop={isDesktop}>
          <Title data-qa-label="topPoolsTitle" isDesktop={isDesktop}>
            {this.props.title}
          </Title>
          <ListContainer
            isDesktop={isDesktop}
            onTouchStart={(e: SyntheticTouchEvent<*>) => handleTouchStart(e)}
            onTouchMove={(e: SyntheticTouchEvent<*>) =>
              this.handleSwipeGTMEvents(e)
            }
          >
            {isDesktop ? this.renderDesktop() : this.renderMobile()}
          </ListContainer>
        </Wrapper>
      )
    );
  }
}
