// @flow
import { get, set, isUndefined } from "lodash";
import type { OperationComponent } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { buildLiveUrl } from "@tvg/formatter/url";
import { mapTalentPicksListFromGraph } from "@tvg/utils/talentPicksUtils";
import type { TalentPickServiceData } from "@tvg/types/TalentPicks";
import type { Talent } from "@tvg/types/Talent";
import { isMultiRaceBet } from "@tvg/sh-lib-my-bets/utils/raceDetails";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import type { TalentPicksRaces } from "../types/talentPicksRaces";

type Props = {
  wagerProfile: string
};

type TalentPickRacesProps = {
  wagerProfile: string,
  talentPicks: Array<TalentPicksRaces>
};

const highlightedOptions = {
  product: tvgConf().product.toUpperCase(),
  brand: tvgConf().brand.toUpperCase(),
  device: "Mobile"
};

const QUERY_VARIABLES = {
  ...highlightedOptions
};

const POLL_INTERVAL = 30000;

const talentWithPickCount = (
  allTalents: TalentPickServiceData[],
  talent: Talent
): Talent => {
  const pickCount = allTalents.reduce(
    (counter, currentValue) =>
      currentValue.talent.talentId === talent.talentId ? counter + 1 : counter,
    0
  );
  return { ...talent, pickCount };
};

const getUniqueTalents = (talents: TalentPickServiceData[]): Talent[] =>
  talents.reduce(
    (uniqueTalents, currentValue) =>
      uniqueTalents.some(
        (elem) => elem.talentId === currentValue.talent.talentId
      )
        ? uniqueTalents
        : uniqueTalents.concat(
            talentWithPickCount(talents, currentValue.talent)
          ),
    []
  );

const getProps = (result) => {
  const tvg1Race = get(result.data, "tvg1Races[0]", null);
  const tvg2Race = get(result.data, "tvg2Races[0]", null);

  let tvg1Link = null;
  let tvg2Link = null;

  if (tvg1Race) {
    tvg1Link = buildLiveUrl(
      get(tvg1Race, "track.code", ""),
      get(tvg1Race, "track.name", ""),
      get(tvg1Race, "number", ""),
      "TVG"
    );
  }
  if (tvg2Race) {
    tvg2Link = buildLiveUrl(
      get(tvg2Race, "track.code", ""),
      get(tvg2Race, "track.name", ""),
      get(tvg2Race, "number", ""),
      "TVG2"
    );
  }

  const mappedTalentPicksListFromGraph = mapTalentPicksListFromGraph(
    get(result, "data.talentPicksRace", get(result, "data.talentPicksList", []))
  );

  return {
    talentPicks: mappedTalentPicksListFromGraph,
    isLoading:
      isUndefined(result.data.talentPicksList) &&
      isUndefined(result.data.talentPicksRace),
    tvg1Link,
    tvg2Link,
    talentsList: getUniqueTalents(mappedTalentPicksListFromGraph)
  };
};

export default {
  skip: (props: Props) => !get(props, "shouldUpdate"),
  options: (props: Props) => {
    const trackCode = get(props, "trackCode", "");
    const raceNumber = get(props, "raceNumber", "");
    const isRace = trackCode !== "" && raceNumber !== "";

    const variables = {
      ...QUERY_VARIABLES,
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      trackCode,
      raceNumber,
      isRace,
      isNotRace: !isRace
    };

    return {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({ ...getProps(result) })
};

const getNeededRacesCode = (talentPicks: Array<TalentPickServiceData>) => {
  return talentPicks.reduce((racesCode, talentPick) => {
    if (
      isMultiRaceBet(talentPick?.wagerType?.abbreviation) &&
      !racesCode.includes(talentPick.track)
    ) {
      racesCode.push(talentPick.track);
    }
    return racesCode;
  }, []);
};

export const RacesApolloClient = {
  skip: (props: TalentPickRacesProps) => {
    const talentPicks = get(props, "talentPicks", []);
    return !getNeededRacesCode(talentPicks).length;
  },
  options: (props: TalentPickRacesProps) => {
    const talentPicks = get(props, "talentPicks", []);

    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      tracksCode: getNeededRacesCode(talentPicks)
    };

    return {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => {
    const races: TalentPicksRaces[] = get(result, "data.talentPicksRaces", []);
    return {
      talentPicksRaces: races.reduce((racesWithKey, track) => {
        set(racesWithKey, track.id, track.races);
        return racesWithKey;
      }, {})
    };
  }
};
