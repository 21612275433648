// @flow
type SelectFilter = {
  type: "RACE_FILTER_SELECTED" | "REGION_FILTER_SELECTED",
  payload: { filterSelected: string }
};

type SetFilters = {
  type: "SET_RACE_FILTERS" | "SET_REGION_FILTERS",
  payload: { filters: string[] }
};

export type Actions = SelectFilter | SetFilters;

export const selectRaceFilter = (filterSelected: string): SelectFilter => ({
  type: "RACE_FILTER_SELECTED",
  payload: { filterSelected }
});

export const selectRegionFilter = (filterSelected: string): SelectFilter => ({
  type: "REGION_FILTER_SELECTED",
  payload: { filterSelected }
});

export const setRaceFiltersFromPreferences = (
  filters: string[]
): SetFilters => ({
  type: "SET_RACE_FILTERS",
  payload: { filters }
});

export const setRegionFiltersFromPreferences = (
  filters: string[]
): SetFilters => ({
  type: "SET_REGION_FILTERS",
  payload: { filters }
});
