// @flow

// $FlowFixMe
import React, { useState, useEffect } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { get, flowRight as compose } from "lodash";
import { useNavigate } from "@tvg/custom-hooks";
import type { Device } from "@tvg/types/Device";
import type { PreviousWinnerWRCResponse } from "@tvg/types/PreviousWinners";
import PreviousWinners, {
  setBetType
} from "@tvg/atomic-ui/_templates/PreviousWinners";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { buildBetSlipUrl } from "@tvg/formatter/url";
import PreviousWinnersMask from "@tvg/atomic-ui/_static/Masks/previousWinners";
import { isTvg5 } from "@tvg/utils/generalUtils";

import PreviousWinnersQuery from "../graphql/queries/PreviousWinners";
import ApolloOptions from "../graphql/options.graph";

type RaceFilter = {
  trackCode: string,
  number: string
};

type Race = {
  id: number,
  tvgRaceId: number,
  mtp: number,
  postTime: string
};

type Winners = [
  PreviousWinnerWRCResponse[],
  PreviousWinnerWRCResponse[],
  PreviousWinnerWRCResponse[]
];

type Props = {
  races: Race[],
  matchRaces: RaceFilter[],
  winners: Winners,
  device: Device,
  isLoading: boolean,
  title: string,
  catchPhrase: string,
  modalTitle: string,
  isMTPNewRules: boolean
};

export const buildPreviousWinners = (winnersPill: Winners, racesList: Race[]) =>
  // $FlowFixMe
  winnersPill.map((winnerList) =>
    winnerList.map((winner) => {
      const race = racesList.find(
        (raceEl) => raceEl.tvgRaceId === winner.currentRace.raceId
      );

      return race
        ? {
            ...winner,
            currentRace: {
              ...winner.currentRace,
              mtp: race.mtp,
              postTime: race.postTime
            }
          }
        : winner;
    })
  );

export function RequestMtpComponent(props: Props) {
  const {
    races,
    winners,
    device,
    isLoading,
    title,
    catchPhrase,
    modalTitle,
    isMTPNewRules
  } = props;

  const [previousWinners, setPreviousWinners] = useState([]);
  const [racesResolved, setRacesResolved] = useState(false);
  const history = useNavigate();
  const hasBiggest = (winnersList: Winners) =>
    winnersList.length > 0 && winnersList[0].length > 0;

  const hasPrevious = (winnersList: Winners) =>
    winnersList.length > 1 && winnersList[1].length > 0;

  const onPreviousWinnersGTM = (winnersList: Winners) =>
    mediator.base.dispatch({
      type: "PREVIOUS_WINNERS:SEEN",
      payload: {
        hasBiggest: hasBiggest(winnersList),
        hasPrevious: hasPrevious(winnersList)
      }
    });

  const onContainerSwipeHandler = (swipeSide: string) =>
    mediator.base.dispatch({
      type: "PREVIOUS_WINNERS:SWIPE",
      payload: {
        swipeSide
      }
    });

  const onSeeAllClickHandler = () =>
    mediator.base.dispatch({ type: "PREVIOUS_WINNERS:SEE_ALL" });

  const navigateToBetSlip = (
    url: string,
    winner: PreviousWinnerWRCResponse
  ) => {
    mediator.base.dispatch({
      type: "TVG4_NAVIGATION",
      payload: { route: url }
    });
    const newUrl = buildBetSlipUrl(
      winner.currentRace.trackCode,
      winner.currentRace.raceNumber.toString(),
      winner.currentRace.bettingInterestNumber,
      false
    );

    if (isTvg5()) {
      const runner = winner.currentRace.bettingInterestNumber;
      history.push(`${url}&selectedrunner=${runner}&betType=10`);
    } else {
      window.open(newUrl, "", "width=535,height=778");
    }
  };

  const onPreviousWinnerSelection = (
    winner: PreviousWinnerWRCResponse,
    url: string,
    event: ?SyntheticKeyboardEvent<*>
  ) => {
    if (event && device === "desktop" && !isTvg5()) {
      event.preventDefault();
      // $FlowFixMe
      document.body.classList.remove("modal");
    }

    const runner = winner.currentRace.bettingInterestNumber;
    const newUrl = tvgConf().buildUrl({
      path: `${url}&selectedRunner=${runner}`
    });

    mediator.base.dispatch({
      type: "PREVIOUS_WINNERS:SELECT_WINNER",
      payload: {
        winner,
        url: newUrl
      }
    });

    if (device === "desktop" && !isTvg5()) {
      navigateToBetSlip(url, winner);
    }
  };

  useEffect(() => {
    if (races && races.length) {
      setPreviousWinners(buildPreviousWinners(winners, races));
      setRacesResolved(true);
    }
  }, [winners, races]);

  useEffect(() => {
    if (hasBiggest(previousWinners) || hasPrevious(previousWinners))
      onPreviousWinnersGTM(previousWinners);
  }, [racesResolved]);

  const hasWinners = get(winners, "[2]", []).length > 0;

  return isLoading || (hasWinners && !racesResolved) ? (
    <PreviousWinnersMask isDesktop={device === "desktop"} />
  ) : (
    <PreviousWinners
      winners={previousWinners}
      isLoading={isLoading}
      device={device}
      isDesktop={device === "desktop"}
      title={title}
      catchPhrase={catchPhrase}
      modalTitle={modalTitle}
      onSelection={onPreviousWinnerSelection}
      onContainerSwipe={onContainerSwipeHandler}
      onSeeAllClick={onSeeAllClickHandler}
      isMTPNewRules={isMTPNewRules}
      bet
    />
  );
}

export default compose(
  graphql(PreviousWinnersQuery, {
    ...ApolloOptions
  })
)(RequestMtpComponent);
