// @flow
export type ToggleOpenTrackRow = {
  type: "TOGGLE_TRACK",
  payload: {
    openTracks: string[]
  }
};

export const toggleOpenTrackRow = (
  openTracks: string[]
): ToggleOpenTrackRow => ({
  type: "TOGGLE_TRACK",
  payload: { openTracks }
});

export default toggleOpenTrackRow;
