import React from "react";

import {
  Paragraph,
  Tag,
  Header,
  useTheme,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import { SaddleClothSilk } from "@tvg/design-system/web/shared";
import { isFDR, isURP } from "@tvg/utils/generalUtils";
import { get } from "lodash";
import {
  Container,
  RunnerJockeyTrainerContainer,
  JockeyTrainerContainer,
  JockeyTrainer,
  OddsContainer
} from "./styled-components";
import { Props } from "./types";
import { getRunnerOdds } from "./utils";
import { buildQaLabel } from "../../utils";
import { CONTAINER, JOCKEY, ODDS, TRAINER } from "../../utils/constants";

const RunnerInfo = ({
  onClick,
  runnerBI,
  isDriver,
  allRunnersHasSilksError = false,
  hasBottomDivider,
  qaLabel = "race-card-runner"
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const runner = runnerBI.runners[0];

  return (
    <Container
      onClick={onClick}
      hasBottomDivider={hasBottomDivider}
      data-qa-label={buildQaLabel([qaLabel, CONTAINER])}
    >
      <SaddleClothSilk
        number={get(runnerBI.runners, "[0].runnerId", `${runnerBI.biNumber}`)}
        numberColor={runnerBI.numberColor}
        saddleColor={runnerBI.saddleColor}
        silkImage={runner?.timeform?.silkUrlSvg}
        allRunnersHasSilksError={allRunnersHasSilksError}
        isDesktop={!isMobile}
        useUpdatedSilk={isFDR() && isURP && isMobile}
      />
      <RunnerJockeyTrainerContainer
        data-qa-label={buildQaLabel([qaLabel, JOCKEY, TRAINER, CONTAINER])}
      >
        <Paragraph
          fontFamily="bold"
          qaLabel="runner-name"
          color={theme.colorTokens.background.promotional}
          lineHeight={20}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {runner?.horseName || ""}
        </Paragraph>
        <JockeyTrainerContainer>
          <JockeyTrainer
            data-qa-label={buildQaLabel([qaLabel, JOCKEY])}
            theme={theme}
            hideEllipsis
          >
            {`${isDriver ? "D" : "J"}: ${runner.jockey}`}
          </JockeyTrainer>
          {isMobile && <span>|</span>}
          <JockeyTrainer
            data-qa-label={buildQaLabel([qaLabel, TRAINER])}
            theme={theme}
          >{`T: ${runner?.trainer}`}</JockeyTrainer>
        </JockeyTrainerContainer>
      </RunnerJockeyTrainerContainer>
      <OddsContainer data-qa-label={buildQaLabel([qaLabel, ODDS, CONTAINER])}>
        {runnerBI.isFavorite && (
          <Tag
            size="s"
            variant="fav"
            label="FAV"
            mr="space-3"
            my="auto"
            qaLabel={buildQaLabel([qaLabel, ODDS, "tag"])}
          />
        )}
        <Header
          tag="h3"
          fontFamily="medium"
          qaLabel={buildQaLabel([qaLabel, "current", "odd"])}
          color={theme.colorTokens.content.default}
        >
          {getRunnerOdds(runnerBI.currentOdds)}
        </Header>
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, "morning", "line", "odd"])}
          color={theme.colorTokens.content.subtle}
          fontSize="xs"
        >
          {`ML ${getRunnerOdds(runnerBI.morningLineOdds)}`}
        </Paragraph>
      </OddsContainer>
    </Container>
  );
};

export default RunnerInfo;
