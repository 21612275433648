import { differenceInDays } from "date-fns";
import { BannerConfigType } from "./types";

export const getEventType = (
  todayDate?: Date,
  eventDate?: Date | null
): BannerConfigType => {
  let eventType = BannerConfigType.DEFAULT;

  if (!todayDate || !eventDate) {
    return BannerConfigType.DEFAULT;
  }

  const eventDateWithoutTime = new Date(
    Date.UTC(
      eventDate.getUTCFullYear(),
      eventDate.getUTCMonth(),
      eventDate.getUTCDate()
    )
  );

  const diffInDays = differenceInDays(todayDate, eventDateWithoutTime);

  switch (diffInDays) {
    case 0:
      eventType = BannerConfigType.EVENT_DAY;
      break;
    case -1:
      eventType = BannerConfigType.FRIDAY_PRE_EVENT;
      break;
    case -2:
      eventType = BannerConfigType.THURSDAY_PRE_EVENT;
      break;
    default:
      if (diffInDays < -2 && diffInDays > -6) {
        eventType = BannerConfigType.PRE_EVENT;
      }
  }

  return eventType;
};
