import React from "react";
import { DialogMessage, Paragraph } from "@tvg/design-system";
import { useSelector } from "react-redux";
import { Props } from "./types";
import {
  Wrapper,
  ParagraphContainer,
  DialogContainer
} from "./styled-components";
import { getPopularRacesNoRacesContent } from "../../redux/selectors";

export const NoRacesAvailable = ({ qaLabel, sectionTitle = "" }: Props) => {
  const { title, description } = useSelector(getPopularRacesNoRacesContent);

  return (
    <Wrapper>
      {sectionTitle && (
        <ParagraphContainer>
          <Paragraph qaLabel="carousel-title" fontSize={18} fontFamily="bold">
            {sectionTitle}
          </Paragraph>
        </ParagraphContainer>
      )}
      <DialogContainer data-qa-label={qaLabel}>
        <DialogMessage title={title} description={description} variant="info" />
      </DialogContainer>
    </Wrapper>
  );
};
