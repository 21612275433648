// @flow
import { combineReducers } from "redux";
import quickLinksReducer from "@tvg/quick-links/redux-manager";

import homeReducer, {
  type State as HomeState,
  initialState as initialHomeState
} from "@tvg/home/src/store/reducers";

import topRacesReducer, {
  type State as TopRacesState,
  initialState as initialTopRacesState
} from "@tvg/top-races/src/reducers";

import tracksBuilderReducer, {
  type State as TracksBuilderState,
  initialState as initialTracksBuilderState
} from "@tvg/tracks-builder/src/reducers";

import {
  type QuickLinksState as QuickLinksContentState,
  initialState as initialQuickLinksContentState
} from "@tvg/quick-links/redux-manager/reducers/content";

import {
  type QuickLinksState as QuickLinksRenderingState,
  initialState as initialQuickLinksRenderingState
} from "@tvg/quick-links/redux-manager/reducers/rendering";

import promosOnboardingReducer, {
  type PromoOnboardingState,
  initialState as initialPromosOnboardingState
} from "@tvg/sh-lib-promos-onboarding/redux/reducers";

import capiReducer, {
  type State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  type State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

import appReducer, {
  type State as AppState,
  initialState as initialAppState
} from "./App/appReducer";

export type State = {
  capi: CapiState,
  userData: UserState,
  app: AppState,
  home: HomeState,
  quickLinks: {
    content: QuickLinksContentState,
    rendering: QuickLinksRenderingState
  },
  topRaces: TopRacesState,
  topTracks: TracksBuilderState,
  promosOnboarding: PromoOnboardingState
};

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  app: initialAppState,
  home: initialHomeState,
  quickLinks: {
    content: initialQuickLinksContentState,
    rendering: initialQuickLinksRenderingState
  },
  topRaces: initialTopRacesState,
  topTracks: initialTracksBuilderState,
  promosOnboarding: initialPromosOnboardingState
};

export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  app: appReducer,
  home: homeReducer,
  quickLinks: quickLinksReducer,
  topRaces: topRacesReducer,
  topTracks: tracksBuilderReducer,
  promosOnboarding: promosOnboardingReducer
});
