// @flow
import type { ToggleOpenTrackRow } from "./actions";

export type State = {
  openTracks: string[]
};

export const initialState = {
  openTracks: []
};

export default function reducer(
  state: State = initialState,
  action: ToggleOpenTrackRow
): State {
  switch (action.type) {
    case "TOGGLE_TRACK": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    default: {
      return state;
    }
  }
}
