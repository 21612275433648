import React from "react";
import { get } from "lodash";

import DerbyBanner from "@urp/derby-banner";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { BannerContext, DerbyEventStatus } from "@urp/derby-banner/src/types";
import { useDerbyBannerConfig } from "../../hooks/useDerbyBannerConfig";

export const HomeHeader = ({ context }: { context: BannerContext }) => {
  const { loading, derbyRace, bannerCfg, eventStatus, onBannerCtaClick } =
    useDerbyBannerConfig({
      context: BannerContext.HOME_PAGE
    });
  const postTimeAvailableRace = get(derbyRace, "postTime");
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const isDeskAndHomepageView =
    isDesktop && context === BannerContext.HOME_PAGE;

  if (loading) {
    return null;
  }

  return (
    <DerbyBanner
      raceStartDate={
        postTimeAvailableRace ? new Date(postTimeAvailableRace) : null
      }
      eventStatus={eventStatus as unknown as DerbyEventStatus}
      bannerCfg={bannerCfg}
      orientation={isDeskAndHomepageView ? "landscape" : "portrait"}
      onCtaClick={onBannerCtaClick}
    />
  );
};

export default HomeHeader;
