// @flow
import React, { PureComponent, type Node } from "react";
import type { Device } from "@tvg/types/Device";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import {
  Container,
  ContainerHeader,
  ContainerTitle
} from "./styled-components";
import TracksMask from "../../_static/Masks/tracks";
import EmptyStateMessages, {
  type EmptyMessages
} from "../../_molecule/EmptyStateMessages";

type Props = {
  device: Device,
  templateTitle: ?string,
  children: ?(Node[]),
  isLoading: boolean,
  hasError: boolean,
  onClearFilters: NullaryFn<void>,
  hasActiveFilters: boolean,
  emptyMessages: EmptyMessages
};

export default class TracksAZ extends PureComponent<Props> {
  static defaultProps = {
    device: "mobile",
    templateTitle: "Tracks AZ",
    children: null,
    isLoading: true,
    hasError: false,
    onClearFilters: noop,
    hasActiveFilters: false,
    emptyMessages: {}
  };

  onClearFilters = () => {
    this.props.onClearFilters();
  };

  renderMessageType = () => {
    if (this.props.hasError) {
      return "error";
    }
    if (
      this.props.children &&
      this.props.children.length === 0 &&
      !this.props.hasActiveFilters
    ) {
      return "noraces";
    }
    return "info";
  };

  render() {
    const { isLoading, templateTitle, children, device } = this.props;
    return isLoading ? (
      <TracksMask />
    ) : (
      <Container data-qa-label="tracksAZContainer">
        <ContainerHeader
          data-qa-label="tracksAZHeader"
          isDesktop={device === "desktop"}
        >
          <ContainerTitle data-qa-label="tracksAZTitle">
            {templateTitle}
          </ContainerTitle>
        </ContainerHeader>
        {!!children && children.length > 0 ? (
          children
        ) : (
          <EmptyStateMessages
            messageType={this.renderMessageType()}
            onClearFilters={this.onClearFilters}
            emptyMessages={this.props.emptyMessages}
          />
        )}
      </Container>
    );
  }
}
