// @flow
import React, { Component } from "react";
import { noop, get } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { TopTrackRace } from "@tvg/types/Track";
import { buildRaceUrl } from "@tvg/formatter";
import type { UnaryFn, BinaryFn, QuinFn } from "@tvg/types/Functional";
import {
  handleTouchMove,
  handleTouchStart,
  evaluateReachingEdge
} from "@tvg/utils/swipeEventHandlers";
import buildColor from "../../_static/ColorPalette";
import {
  Container,
  TrackInfo,
  FavoriteIconWrapper,
  TrackName,
  TextEllipsis,
  DesktopWrapper,
  PaginationWrapper,
  RaceList,
  ResultedTag
} from "./styled-components";
import Icon from "../../_static/Icons";
import {
  starOutline,
  star,
  promosolid,
  arrowBack,
  arrowForward
} from "../../_static/Icons/icons";
import Tag from "../../_atom/Tag";
import TrackRaceLink from "../../_molecule/TrackRaceLink";
import DefaultButton from "../../_atom/Buttons/default";

type ScrollActionArgs = {
  left: number,
  behavior: "smooth"
};

type ScrollListElement = HTMLElement & {
  scroll: UnaryFn<ScrollActionArgs, void>
};

type State = {
  disableLeftScroll: boolean,
  disableRightScroll: boolean
};

type Props = {
  device: Device,
  trackRaces: TopTrackRace[],
  qaLabel: string,
  trackCode: string,
  trackName: string,
  isGreyhound: boolean,
  isFavorite: ?boolean,
  hasPromo: boolean,
  hasResultedState: boolean,
  sendGTMScrollEvent: UnaryFn<boolean, void>,
  handleFavoriteChange: BinaryFn<boolean, string, void>,
  onRaceSelect: QuinFn<string, string, TopTrackRace, string, boolean, void>,
  isMTPNewRules: boolean
};

export const findMissingScrollAmount = (
  currentScrollAmount: number
): number => {
  const stringValue = currentScrollAmount.toString();
  return +stringValue.substring(stringValue.length - 2, stringValue.length);
};

export default class TopTrackLine extends Component<Props, State> {
  static defaultProps = {
    device: "mobile",
    trackRaces: [],
    qaLabel: "",
    trackCode: "",
    trackName: "",
    isGreyhound: false,
    isFavorite: false,
    hasPromo: false,
    hasResultedState: false,
    sendGTMScrollEvent: noop,
    handleFavoriteChange: noop,
    onRaceSelect: noop,
    isMTPNewRules: false
  };

  constructor(props: Props) {
    super(props);
    this.SCROLL_AMOUNT = 100;
    this.shallDisplayNavigationArrows = true;
    this.state = {
      disableLeftScroll: true,
      disableRightScroll: true
    };
  }

  componentDidMount() {
    const tempRaceList: HTMLElement | null = document.getElementById(
      `${this.props.qaLabel}RaceList`
    );
    if (tempRaceList !== null) {
      const navigationStatus = {
        disableLeftScroll: tempRaceList.scrollLeft === 0,
        disableRightScroll:
          tempRaceList.offsetWidth + tempRaceList.scrollLeft >=
          tempRaceList.scrollWidth
      };
      /* eslint-disable */ this.setState({
        ...navigationStatus
      }); /* eslint-enable */
      this.shallDisplayNavigationArrows = !navigationStatus.disableRightScroll;
    }
  }

  raceList: ScrollListElement;

  shallDisplayNavigationArrows: boolean;

  SCROLL_AMOUNT: 100;

  scrollRaceList = (amount: number) => {
    this.setState({
      disableLeftScroll: this.raceList.scrollLeft + amount <= 0,
      disableRightScroll:
        this.raceList.offsetWidth + this.raceList.scrollLeft + amount >=
        this.raceList.scrollWidth
    });
    this.raceList.scroll({
      left: this.raceList.scrollLeft + amount,
      behavior: "smooth"
    });
  };

  scrollForward = () => {
    this.props.sendGTMScrollEvent(false);
    this.scrollRaceList(this.SCROLL_AMOUNT);
  };

  scrollBack = () => {
    const amountToScroll = this.state.disableRightScroll
      ? -findMissingScrollAmount(this.raceList.scrollLeft)
      : -this.SCROLL_AMOUNT;
    this.props.sendGTMScrollEvent(true);
    this.scrollRaceList(amountToScroll);
  };

  handleSwipeGTMEvents = (e: SyntheticTouchEvent<*>) => {
    let swipeDirection = handleTouchMove(e);
    const isLivingOnTheEdge = evaluateReachingEdge(e);
    swipeDirection = isLivingOnTheEdge ? 0 : swipeDirection;
    if (swipeDirection !== 0) {
      this.props.sendGTMScrollEvent(swipeDirection < 0);
    }
  };

  renderTrackRaceLinks = (isDesktop: boolean) =>
    this.props.trackRaces.map((trackRace: TopTrackRace) => {
      const hasPromo =
        (Array.isArray(trackRace.promos) && !!trackRace.promos.length) ||
        !!get(trackRace, "userPromotions.length", 0);
      const programPageURL = buildRaceUrl(
        this.props.trackCode,
        this.props.trackName,
        trackRace.number,
        this.props.isGreyhound
      );
      const userPromos = get(trackRace, "userPromotions", []) || [];
      const isOptedIn =
        trackRace.optedIn ||
        userPromos.some((promo) => get(promo, "optedIn", false));
      let promoState = "no promo";

      if (hasPromo || userPromos.length) {
        promoState = isOptedIn ? "promo optin" : "promo";
      }

      return (
        <li
          key={`trackRaceLink_${trackRace.number}`}
          data-qa-label={`topTrackRace${trackRace.number}Item`}
        >
          <TrackRaceLink
            onClickCallback={(event) => {
              if (isDesktop) {
                event.preventDefault();
              }
              this.props.onRaceSelect(
                programPageURL,
                this.props.trackName,
                trackRace,
                promoState,
                !!this.props.isFavorite
              );
            }}
            raceNumber={trackRace.number}
            raceTime={trackRace.postTime}
            hasPromo={hasPromo}
            optedIn={isOptedIn}
            qaLabel="topTrackRace"
            mtp={trackRace.mtp}
            status={trackRace.status.code}
            to={programPageURL}
            isMTPNewRules={this.props.isMTPNewRules}
          />
        </li>
      );
    });

  renderTrackPromoTag = () =>
    this.props.hasPromo && (
      <Tag
        qaLabel={`${this.props.qaLabel}PromoTag`}
        icon={promosolid}
        tagType="PromoTag"
        name="Promo"
        iconSize={16}
      />
    );

  renderTrackState = () =>
    this.props.hasResultedState ? (
      <ResultedTag>Resulted</ResultedTag>
    ) : (
      this.renderTrackPromoTag()
    );

  renderFavoriteIcon = () =>
    this.props.isFavorite ? (
      <Icon
        qaLabel={`${this.props.qaLabel}FavoriteIcon`}
        icon={star}
        color={buildColor("yellow", "500")}
      />
    ) : (
      <Icon
        qaLabel={`${this.props.qaLabel}StarOutlineIcon`}
        icon={starOutline}
        color={buildColor("blue", "200")}
      />
    );

  renderRaceList = () =>
    this.props.device === "desktop" ? (
      <DesktopWrapper data-qa-label={`${this.props.qaLabel}Container`}>
        {this.shallDisplayNavigationArrows && (
          <PaginationWrapper>
            <DefaultButton
              isDisabled={this.state.disableLeftScroll}
              onClick={() => this.scrollBack()}
              qaLabel={`${this.props.qaLabel}BackButton`}
            >
              <Icon icon={arrowBack} />
            </DefaultButton>
          </PaginationWrapper>
        )}
        <RaceList
          data-qa-label={`${this.props.qaLabel}RaceList`}
          ref={(raceList) => {
            if (raceList) {
              this.raceList = raceList;
            }
          }}
          device={this.props.device}
          id={`${this.props.qaLabel}RaceList`}
          hasNavigation={
            this.props.device === "desktop" && this.shallDisplayNavigationArrows
          }
        >
          {this.renderTrackRaceLinks(true)}
        </RaceList>
        {this.shallDisplayNavigationArrows && (
          <PaginationWrapper isRight>
            <DefaultButton
              isDisabled={this.state.disableRightScroll}
              onClick={() => this.scrollForward()}
              qaLabel={`${this.props.qaLabel}ForwardButton`}
            >
              <Icon icon={arrowForward} />
            </DefaultButton>
          </PaginationWrapper>
        )}
      </DesktopWrapper>
    ) : (
      <RaceList
        id={`${this.props.qaLabel}RaceList`}
        onTouchStart={(e: SyntheticTouchEvent<*>) => handleTouchStart(e)}
        onTouchMove={(e: SyntheticTouchEvent<*>) =>
          this.handleSwipeGTMEvents(e)
        }
        data-qa-label={`${this.props.qaLabel}RaceList`}
      >
        {this.renderTrackRaceLinks(false)}
      </RaceList>
    );

  render() {
    return (
      <Container
        data-qa-label={`${this.props.qaLabel}${this.props.trackCode}Container`}
      >
        <TrackInfo data-qa-label={`${this.props.qaLabel}TrackInfo`}>
          <FavoriteIconWrapper
            onClick={() =>
              this.props.handleFavoriteChange(
                !this.props.isFavorite,
                this.props.trackCode
              )
            }
            data-qa-label={`${this.props.qaLabel}FavoriteIconWrapper`}
          >
            {this.renderFavoriteIcon()}
          </FavoriteIconWrapper>
          <TrackName data-qa-label={`${this.props.qaLabel}TrackName`}>
            <TextEllipsis>{this.props.trackName}</TextEllipsis>
          </TrackName>
          {this.renderTrackState()}
        </TrackInfo>
        {this.renderRaceList()}
      </Container>
    );
  }
}
