import styled, { css } from "styled-components";

export const VideoContainer = styled.div<{
  width: number | undefined;
  height: number | undefined;
}>`
  position: relative;
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : ""}

  ${({ height }) =>
    height
      ? css`
          height: ${height}px;
        `
      : ""}
`;

export const TitleContainer = styled.div<{ title: string | undefined }>`
  ${({ title }) => !title && "display: none;"}
  position: absolute;
  top: var(--space-space3, 12px);
  left: var(--space-space3, 12px);

  p {
    color: var(--fd-colors-content-on-dark);
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: var(--space-space3, 12px);
  right: var(--space-space3, 12px);
  cursor: pointer;

  path {
    stroke: var(--fd-colors-background-surface);
  }
`;

export const Loading = styled.div<{
  width?: number;
  height?: number;
}>`
  display: flex;
  justify-content: center;
  background-color: var(--fd-colors-core-black-tint-090);
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;
