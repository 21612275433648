// @flow

import styled, { css } from "styled-components";
import { buildTitle } from "../../_atom/Title";
import buildColor from "../../_static/ColorPalette";
import { fontBold } from "../../_static/Typography";

const PromoWidgetContainer = styled.div`
  background-color: ${buildColor("white", "100")};
  padding: 15px 0 0;
  box-shadow: inset 0 0 0 1px ${buildColor("blue", "100")};
  ${(props) =>
    props.device === "desktop" &&
    css`
      border-radius: 4px;
      padding: 17px 0 8px;
    `}
`;

export const PromosWidgetTitle = styled(
  buildTitle({
    tag: "h2",
    fontSize: 18,
    color: buildColor("grey", "900")
  })
)`
  font-family: ${fontBold};
  font-weight: bold;
  padding: 0 12px 10px;
  ${(props) =>
    props.smallFont &&
    css`
      font-size: 16px;
      padding: 0 12px 6px;
      height: 19px;
    `}
`;

export default PromoWidgetContainer;
