import { PastRaceResults } from "@tvg/ts-types/Race";
import { ToggleHideResultsEnum } from "../../types";

type ToggleHideResults = {
  type: "TOGGLE_HIDE_RESULTS";
  payload: {
    hideResults: ToggleHideResultsEnum;
  };
};

type AddPastRaceCache = {
  type: "ADD_PAST_RACE_CACHE";
  payload: {
    key: string;
    pastRace: PastRaceResults;
  };
};

export type Actions = ToggleHideResults | AddPastRaceCache;

export const toggleHideResults = (
  hideResults: ToggleHideResultsEnum
): ToggleHideResults => ({
  type: "TOGGLE_HIDE_RESULTS",
  payload: { hideResults }
});

export const addPastRaceCache = (
  key: string,
  pastRace: PastRaceResults
): AddPastRaceCache => ({
  type: "ADD_PAST_RACE_CACHE",
  payload: { key, pastRace }
});
