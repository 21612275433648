// @flow
import React, { PureComponent } from "react";
import { noop } from "lodash";
import type { UnaryFn } from "@tvg/types/Functional";

import { AlertLineContainer, Title, Image } from "./styled-components";
import Toggle from "../../_atom/Toggle";

type Props = {
  /**
   * Url for image to be placed at start of line
   */
  pictureUrl?: string,
  /**
   * Title of alert
   */
  title: string,
  /**
   * Indicate if this alert is active
   */
  isActive: boolean,
  /**
   * Callback to click on toggle
   */
  onToggleClick: UnaryFn<boolean, mixed>,
  /**
   * qaLabel for test
   */
  qaLabel: string
};

export default class AlertLine extends PureComponent<Props> {
  static defaultProps = {
    pictureUrl: "",
    title: "",
    onToggleClick: noop,
    isActive: false,
    qaLabel: ""
  };

  render() {
    const qaLabel = {
      ...(this.props.qaLabel !== ""
        ? {
            "data-qa-label": this.props.qaLabel
          }
        : {})
    };
    return (
      <AlertLineContainer {...qaLabel}>
        {this.props.pictureUrl && (
          <Image src={this.props.pictureUrl} alt={this.props.title} />
        )}
        <Title>{this.props.title}</Title>
        <Toggle
          on={this.props.isActive}
          onClick={() => this.props.onToggleClick(this.props.isActive)}
        />
      </AlertLineContainer>
    );
  }
}
