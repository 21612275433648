// @flow
import styled from "styled-components";
import { buildTitle } from "../../_atom/Title";
import buildColor from "../../_static/ColorPalette";

export const AlertLineContainer = styled.div`
  display: flex;
  height: 48px;
  flex-direction: row;
  align-items: center;
  background-color: ${buildColor("white", "100")};
  padding: 0 2px;
`;

export const Title = styled(
  buildTitle({
    tag: "h4",
    uppercase: true,
    fontSize: 14,
    color: buildColor("grey", "900"),
    ellipsis: true
  })
)`
  flex-grow: 1;
`;

export const Image = styled.img`
  height: 44px;
  margin-right: 8px;
  align-self: flex-end;
`;
