// @flow

import React, { PureComponent } from "react";
import type { TernaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import type { Promo } from "@tvg/types/Promos";
import PromosWidgetItem from "../../_molecule/PromosWidgetItem";
import PromoWidgetContainer, { PromosWidgetTitle } from "./styled-components";

type Props = {
  promosWidgetList: Promo[],
  onPromoItemClick: TernaryFn<Event, string, string, void>,
  title: string,
  device: Device,
  hasLargeWidth?: boolean
};

export default class PromosWidget extends PureComponent<Props> {
  static defaultProps = {
    promosWidgetList: [],
    title: "Promotions Calendar",
    device: "mobile",
    hasLargeWidth: false
  };

  renderPromosList = () =>
    this.props.promosWidgetList.map((promoRow: Promo, index: number) => (
      <PromosWidgetItem
        key={promoRow.name}
        onPromoItemClick={this.props.onPromoItemClick}
        promosWidgetItem={promoRow}
        qaLabelPrefix={`promo${index}_`}
        device={this.props.device}
        hasLargeWidth={this.props.hasLargeWidth}
      />
    ));

  render() {
    return (
      !!this.props.promosWidgetList.length && (
        <PromoWidgetContainer
          device={this.props.device}
          data-qa-label="promoWidgetContainer"
        >
          <PromosWidgetTitle
            data-qa-label="promoWidgetTitle"
            smallFont={
              this.props.device !== "desktop" || this.props.hasLargeWidth
            }
          >
            {this.props.title}
          </PromosWidgetTitle>
          {this.renderPromosList()}
        </PromoWidgetContainer>
      )
    );
  }
}
