// @flow
import type { RacePanelLink } from "@tvg/types/Race";

export type Actions = {
  type: "SEND_TOP_RACES",
  payload: { topRaces: RacePanelLink[] }
};

export default (topRaces: RacePanelLink[]): Actions => ({
  type: "SEND_TOP_RACES",
  payload: { topRaces }
});
