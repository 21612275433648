import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import ButtonDefault from "../../_atom/Buttons/default";
import buildColor from "../../_static/ColorPalette";
import { fontBold, fontMedium } from "../../_static/Typography";

export const PicksWrapper = styled.div`
  align-self: center;
  flex-grow: 1;
  position: relative;
  width: 100%;
  ${({ isOnProgramPage, isOnTool, isOnHomePage }) =>
    !isOnProgramPage &&
    !isOnTool &&
    !isOnHomePage &&
    css`
      margin: auto;
      @media (min-width: 486px) {
        max-width: 414px;
      }

      @media (min-width: 1200px) {
        max-width: 485px;
      }

      @media (min-width: 1366px) {
        max-width: 552px;
      }
    `}

  & > article {
    margin-top: 16px;
    margin-bottom: 1px;
  }

  & > * {
    flex: 0 0 auto;
  }

  ${(props) =>
    props.isEmpty &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
    `};
  ${(props) =>
    props.isHorizontalLayout &&
    css`
      display: flex;
      align-items: stretch;
      overflow: hidden;

      & > article:first-child {
        margin-left: 4px;
      }
    `};
  ${({ onModalDesktop }) =>
    onModalDesktop &&
    css`
      width: 292px;
    `};
`;

export const TalentPickWrapper = styled.div`
  ${({ isDesktop, isTvg5, talentPickWrapperWidth }) =>
    isDesktop
      ? css`
          max-width: ${isTvg5 ? talentPickWrapperWidth : "312px"};
        `
      : css`
          margin-left: 8px;
          max-width: 290px;

          &:first-of-type {
            margin-left: ${(props) => !props.isDesktop && "12px"};
          }
        `};
  ${({ isLastCard }) =>
    isLastCard &&
    css`
      margin-right: calc(100% - 292px);
    `};
  padding: 12px 0 2px;
`;

export const TalentPicksContainer = styled.section`
  align-self: center;
  ${({ isOnProgramPage, isOnTool, isEmpty }) =>
    !isOnProgramPage &&
    !isOnTool &&
    !isEmpty &&
    css`
      position: relative;
    `};
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  ${(props) =>
    props.hasNewButton &&
    css`
      padding-bottom: 80px;
    `};
  ${(props) =>
    props.isHorizontalLayout &&
    css`
      background-color: ${buildColor("white", "100")};
      padding-bottom: 12px;
    `};

  & > .talentPicksWatchLiveBox {
    background-color: ${buildColor("white", "100")};
    margin-top: 8px;
    padding-top: 16px;
    white-space: pre-line;

    & a {
      margin-top: 8px;
      margin-right: 8px;
      padding: 10px 8px;
      width: 102px;
    }
  }

  & .emptyStateContainer {
    left: 0;
    right: 0;
  }

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-bottom: 12px;
    `};
  ${({ isEmpty, onProgramPage }) =>
    isEmpty &&
    onProgramPage &&
    css`
      background-color: ${buildColor("white", "100")};
    `};
`;

export const WatchLiveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-top: 8px;

  a,
  button {
    font-size: 14px;

    span {
      font-family: ${fontMedium};
    }
  }

  a:not(:last-child) {
    margin-right: 8px;
  }
`;

export const WatchLiveLabel = styled.span`
  margin-left: 4px;
  white-space: nowrap;
`;

export const NewButton = styled(ButtonDefault)`
  width: 50px;
  height: 50px;
  padding: 8px;
  font-size: 9px;
  border-radius: 50%;
  background-color: ${buildColor("blue", "500")};
  color: ${buildColor("white", "100")};
  font-weight: bold;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.16), 0 0 1px 0 rgba(0, 0, 0, 0.24);
  position: fixed;
  bottom: 16px;

  @media (max-width: 600px) {
    right: 8px;
  }

  @media (min-width: 600px) {
    left: 50%;
    transform: translate(-50%, 0);
    margin-left: 251px;
  }
`;

export const MessageContainer = styled.div`
  width: 90%;
  top: ${(props) => (props.isFilterEnabled ? `86px` : `0px`)};
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 280px;

  & a {
    display: block;
    width: 135px;
    height: 188px;
  }

  & .liveButtonsWrapper {
    display: flex;
    align-items: space-between;
    padding-top: 18px;
  }
`;

export const Title = styled.h1`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-weight: bold;
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          font-size: 18px;
          margin-right: 12px;
        `
      : css`
          font-size: 16px;
        `};
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 12px 12px 0;
  align-items: center;
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          position: absolute;
          height: 46px;
        `
      : css`
          justify-content: space-between;
        `}
`;

const linkStyle = `
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontMedium};
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;
  text-align:right;

  :hover {
    cursor:pointer;
      text-decoration: underline;
    }
  `;

export const RedirectLink = styled(Link)`
  ${linkStyle}
`;

export const RedirectLinkDesktop = styled.a`
  ${linkStyle};
  margin-top: 4px;
`;
