// @flow
import TracksComponents, { type TracksTypes } from "./types";
import TopTracks from "./top-tracks";
import TracksAZ from "./tracks-az";
import FavoriteTracks from "./tracks-favorite";
import ResultedTracksAZ from "./resulted-tracks-az";
import ResultedFavoriteTracks from "./resulted-tracks-favorite";

const getComponentByType = (componentType: TracksTypes) => {
  switch (componentType) {
    case TracksComponents.TOP_TRACKS:
      return TopTracks;
    case TracksComponents.TRACKS_AZ:
      return TracksAZ;
    case TracksComponents.FAVORITE_TRACKS:
      return FavoriteTracks;
    case TracksComponents.RESULTED_TRACKS_AZ:
      return ResultedTracksAZ;
    case TracksComponents.RESULTED_FAVORITE_TRACKS:
      return ResultedFavoriteTracks;
    default:
      return TopTracks;
  }
};

export default getComponentByType;
