// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import type { PreviousWinnerWRCResponse } from "@tvg/types/PreviousWinners";

import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe(
    "PREVIOUS_WINNERS:SEEN",
    (data: {
      type: "PREVIOUS_WINNERS:SEEN",
      payload: {
        hasBiggest: boolean,
        hasPreviuos: boolean
      }
    }) => {
      const hasBiggest = get(data, "payload.hasBiggest");
      const hasPrevious = get(data, "payload.hasPrevious");
      const gaEventLabel =
        (hasBiggest && hasPrevious && "biggest + previous") ||
        (hasBiggest && "biggest") ||
        "previous";

      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "seen",
        gaEventLabel,
        module: "previous winners",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("PREVIOUS_WINNERS:SEE_ALL", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "open",
      gaEventLabel: "see all",
      module: "previous winners",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "PREVIOUS_WINNERS:SWIPE",
    (data: {
      type: "PREVIOUS_WINNERS:SWIPE",
      payload: {
        swipeSide: string
      }
    }) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.swipeSide"),
        gaEventLabel: undefined,
        module: "previous winners",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PREVIOUS_WINNERS:SELECT_WINNER",
    (data: {
      type: "PREVIOUS_WINNERS:SELECT_WINNER",
      payload: {
        winner: PreviousWinnerWRCResponse,
        url: string
      }
    }) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        // {{track name}}+ ' - ' + {{race number}}+ ' - ' + {{mtp}}
        gaEventLabel: `${get(
          data,
          "payload.winner.currentRace.trackName"
        )} - R${get(data, "payload.winner.currentRace.raceNumber")} - ${get(
          data,
          "payload.winner.currentRace.mtp"
        )}`,
        module: "previous winners",
        // {{horse name}}+ ' - ' + {{ previous win amount }}
        tag: `${get(data, "payload.winner.entityName")} - ${get(
          data,
          "payload.winner.wager.winAmount"
        )}`,
        menu: undefined,
        sport: get(data, "payload.winner.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing",
        destinationUrl: get(data, "payload.url")
      });
    }
  );
};
