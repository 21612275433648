// @flow

import React, { PureComponent } from "react";
import { isNumber, toString, toNumber } from "lodash";
import { RunnerOddsAtom, Strong, Span } from "./styled-components";

const getRunnerOddQALabelSuffix = (
  isFavorite: boolean,
  isScratched: boolean
): string => {
  if (isScratched) {
    return "-scratched";
  }

  if (isFavorite) {
    return "-favorite";
  }

  return "";
};

type Props = {
  /**
   * Does the runner is isScratched
   */
  isScratched: boolean,
  /**
   * Does the runner is favorite
   */
  isFavorite: boolean,
  /**
   * Current odds value
   */
  currentOddsNumerator: ?number,
  /**
   * Current odds value
   */
  currentOddsDenominator: ?number,
  /**
   * Morning line odds value
   */
  morningLineOddsNumerator: ?number,
  /**
   * Current odds value
   */
  morningLineOddsDenominator: ?number
};

export default class RunnerOdds extends PureComponent<Props> {
  static defaultProps = {
    isScratched: false,
    isSelected: false,
    isFavorite: false,
    currentOddsNumerator: null,
    currentOddsDenominator: null,
    morningLineOddsNumerator: null,
    morningLineOddsDenominator: null
  };

  renderOddDenominator() {
    return (
      (isNumber(this.props.currentOddsDenominator) &&
        `/${toString(this.props.currentOddsDenominator)}`) ||
      ""
    );
  }

  renderOdd() {
    if (!this.props.isScratched) {
      if (!isNumber(this.props.currentOddsNumerator)) {
        return "n/a";
      }
      return `${toString(
        this.props.currentOddsNumerator
      )}${this.renderOddDenominator()}`;
    }

    return "scr";
  }

  renderMorningLineDenominator() {
    return (
      (toNumber(this.props.morningLineOddsDenominator) > 1 &&
        `/${toString(this.props.morningLineOddsDenominator)}`) ||
      ""
    );
  }

  renderMorningLine() {
    if (isNumber(this.props.morningLineOddsNumerator)) {
      return `${
        // $FlowFixMe
        this.props.morningLineOddsNumerator
      }${this.renderMorningLineDenominator()}`;
    }
    return "n/a";
  }

  render() {
    return (
      <RunnerOddsAtom
        data-qa-label={`runnerOdds${getRunnerOddQALabelSuffix(
          this.props.isFavorite,
          this.props.isScratched
        )}`}
        scratched={this.props.isScratched}
        favorite={this.props.isFavorite && !this.props.isScratched}
      >
        <Strong qaLabel="runnerOdds-odd">{this.renderOdd()}</Strong>
        <Span qaLabel="runnerOdds-morningLineOdd">
          {this.renderMorningLine()}
        </Span>
      </RunnerOddsAtom>
    );
  }
}
