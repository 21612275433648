// @flow
import type { RacePanelLink } from "@tvg/types/Race";
import type { Actions } from "./actions";

export type State = { races: RacePanelLink[] };

export const initialState = {
  races: []
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SEND_TOP_RACES": {
      return { ...state, races: action.payload.topRaces };
    }
    default: {
      return state;
    }
  }
}
