import React from "react";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { Header } from "@tvg/design-system/web/components/Typography/Header";
import { Paragraph } from "@tvg/design-system/web/components/Typography/Paragraph";
import { Icon } from "@tvg/design-system/web/components/Icon";
import { Button, Tag } from "@tvg/design-system";
import { RaceResultProps } from "./types";
import { RUNNERS_MOCK, PAYOUT_HEADERS_MOCK } from "./mockData";
import { LABELS } from "./constants";
import { LoadingMask } from "./components/LoadingMask";
import {
  Card,
  HeaderSection,
  HeaderLeft,
  BodySection,
  ContentSection,
  ButtonSection,
  BetsButton,
  SeeResultButton,
  RevealOverlay,
  RunnerContainer,
  PayoutsHeader,
  PayoutHeaderValue
} from "./styled-components";
import { RunnerList } from "./components/RunnerList";

export const RaceResult: React.FC<RaceResultProps> = ({
  date,
  time,
  trackName,
  raceNumber,
  raceTypeCode,
  runnerNameHighlight,
  runners,
  showRunners = false,
  isGrouped = false,
  isReplayAvailable,
  isLoading = false,
  betsCounter = 0,
  resultButtonLabel = LABELS.VIEW_RESULTS,
  showRunnersLabel = LABELS.PRESS_TO_REVEAL,
  hideButtons = false,
  payoutHeaders = [],
  onMyBetsClick,
  onWatchReplayClick,
  onSeeResultClick,
  onRevealClick
}) => {
  const colorTokens = useColorTokens();

  if (isLoading) {
    return <LoadingMask qaLabel="past-race-loading" />;
  }

  return (
    <Card isGrouped={isGrouped}>
      <HeaderSection>
        <HeaderLeft>
          {(date || time) && (
            <Paragraph
              qaLabel="date-time"
              color={colorTokens.content.mtp}
              fontSize="xs"
              fontWeight="bold"
              aria-label={LABELS.DATE_TIME}
            >
              {date && time ? `${date} ${time}` : `${date || time}`}
            </Paragraph>
          )}
          <Header tag="h3" color={colorTokens.content.strong}>
            {trackName
              ? `${trackName} - R${raceNumber}`
              : `${LABELS.RACE} ${raceNumber}`}
          </Header>
        </HeaderLeft>
        {betsCounter > 0 && (
          <BetsButton onClick={onMyBetsClick} aria-label={LABELS.MY_BETS}>
            <Tag
              icon="bets"
              size="l"
              variant="highlight"
              label={betsCounter.toString()}
            />
          </BetsButton>
        )}
      </HeaderSection>

      <BodySection>
        <ContentSection>
          <RunnerContainer>
            <PayoutsHeader showRunners={showRunners}>
              {(showRunners ? payoutHeaders : PAYOUT_HEADERS_MOCK).map(
                (header) => (
                  <PayoutHeaderValue
                    key={header}
                    qaLabel={header}
                    fontFamily="medium"
                  >
                    {header}
                  </PayoutHeaderValue>
                )
              )}
            </PayoutsHeader>
            <RunnerList
              raceTypeCode={raceTypeCode}
              runners={showRunners ? runners : RUNNERS_MOCK}
              runnerNameHighlight={runnerNameHighlight}
              showRunners={showRunners}
            />
          </RunnerContainer>
          {!showRunners && (
            <RevealOverlay
              onClick={onRevealClick}
              role="button"
              aria-label={LABELS.REVEAL_RESULTS}
            >
              <Icon
                name="eyeHide"
                size="xl"
                lineColor={colorTokens.content.onDark}
                qaLabel="eye-icon"
              />
              <Paragraph
                qaLabel="press-to-reveal-text"
                color={colorTokens.content.onDark}
                fontSize="s"
                mt="space-4"
              >
                {showRunnersLabel}
              </Paragraph>
            </RevealOverlay>
          )}
        </ContentSection>

        <ButtonSection hidden={hideButtons}>
          {onWatchReplayClick && (
            <Button
              qaLabel="watch-replay-button"
              variant="primary"
              onPress={onWatchReplayClick}
              isDisabled={!isReplayAvailable}
              icon="replay"
              aria-label={LABELS.WATCH_REPLAY}
            >
              {LABELS.EMPTY_STRING}
            </Button>
          )}

          {onSeeResultClick && (
            <SeeResultButton>
              <Button
                qaLabel="see-result-button"
                variant="tertiary"
                onPress={onSeeResultClick}
                isStretched
                aria-label={resultButtonLabel}
              >
                {resultButtonLabel}
              </Button>
            </SeeResultButton>
          )}
        </ButtonSection>
      </BodySection>
    </Card>
  );
};

export default React.memo(RaceResult);
