// @flow
import type { UserInfo } from "@tvg/types/User";
import type { UserOptedInPromos } from "../types";

export type UserData = {
  error: boolean | null,
  hasRequested: boolean,
  logged: boolean,
  logging: boolean,
  user: UserInfo,
  wasLogin: boolean,
  userLogin: boolean,
  userLogout: boolean,
  loginPin: number,
  tvg3token: string,
  allPromos: []
};

type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE",
  payload: UserData
};

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged: boolean
  }
};

export type SetUserAccountNumber = {
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber: string
  }
};

export type setUserPromosAction = {
  type: "USER_PROMOS_SET",
  payload: Array<{ [string]: string }>
};

export type UserPromosSuccess = {
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: UserOptedInPromos }
};

export type UserPromosFail = {
  type: "USER_PROMOS_FAIL",
  payload: {},
  error: true
};

export type UserActions =
  | UserSessionSuccessAction
  | SetUserAccountNumber
  | SetUserLoggedState
  | setUserPromosAction
  | UserPromosSuccess
  | UserPromosFail;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserLoggedState = (isLogged: boolean): SetUserLoggedState => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});

export const setUserPromos = (
  allPromos: Array<{ [string]: string }>
): setUserPromosAction => ({
  type: "USER_PROMOS_SET",
  payload: allPromos
});

export const failUserPromos = (err: Error): UserPromosFail => ({
  type: "USER_PROMOS_FAIL",
  payload: err,
  error: true
});

export const successUserPromos = (
  userPromos: UserOptedInPromos
): UserPromosSuccess => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});
