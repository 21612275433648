// @flow
import React, { Component, Fragment } from "react";
import type { Talent as TalentType } from "@tvg/types/Talent";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import { isTvg5 } from "@tvg/utils/generalUtils";
import tvgConf from "@tvg/conf";
import {
  Header,
  Image,
  MobileLink,
  DesktopLink,
  RaceMTP,
  InfoWrapper,
  Talent,
  TrackRace,
  Track,
  Race
} from "./styled-components";

type Props = {
  ...TalentType,
  imageBaseURL: string,
  isDesktop: boolean,
  mtp: number,
  postTime: string,
  status: string,
  isFeatured: boolean,
  featuredText: string,
  trackName: string,
  trackCode: string,
  race: number,
  onClick: NullaryFn<void>,
  raceUrl: string
};

export default class TalentPickCardHeader extends Component<Props> {
  static defaultProps = {
    talentId: "",
    name: "",
    pictureUrl: "",
    popularity: 0,
    tvgAccountId: "",
    phrases: [],
    imageBaseURL: "//www.tvg.com",
    isDesktop: false,
    mtp: 0,
    postTime: "",
    status: "",
    isFeatured: false,
    featuredText: "",
    trackName: "",
    trackCode: "",
    race: 0,
    onClick: noop,
    raceUrl: ""
  };

  renderContent = () => (
    <Fragment>
      <RaceMTP
        isPlainMTP
        mtp={this.props.mtp}
        postTime={this.props.postTime}
        status={this.props.status}
      />
      <InfoWrapper isPictureShown={!!this.props.pictureUrl}>
        <TrackRace isFeatured={this.props.isFeatured} data-qa-label="trackRace">
          <Track>
            {this.props.isFeatured
              ? this.props.featuredText
              : this.props.trackName}
          </Track>
          <Race>
            {this.props.isFeatured && `- ${this.props.trackCode} `}
            {`R${this.props.race}`}
          </Race>
        </TrackRace>
        <Talent data-qa-label="talentName">{this.props.name}</Talent>
      </InfoWrapper>
      {!!this.props.pictureUrl &&
        !this.props.pictureUrl.includes("tvgpicks_placeholder") &&
        !this.props.pictureUrl.includes("test_talent") && (
          <Image
            data-qa-label="image"
            src={`${this.props.imageBaseURL}/${this.props.pictureUrl}`}
            alt={this.props.name}
          />
        )}
    </Fragment>
  );

  render() {
    const [pathname, search] = (this.props.raceUrl || "").split("?");
    const costumLinkProps =
      this.props.isDesktop && isTvg5()
        ? { to: { pathname, search } }
        : { as: "a", href: this.props.raceUrl };

    return (
      <Header data-qa-label="header">
        {this.props.isDesktop ? (
          <DesktopLink
            {...costumLinkProps}
            data-qa-label="link"
            onClick={(event) => {
              if (!isTvg5()) {
                event.preventDefault();
                this.props.onClick();
              }
            }}
          >
            {this.renderContent()}
          </DesktopLink>
        ) : (
          <MobileLink
            data-qa-label="link"
            to={this.props.raceUrl}
            onClick={() => {
              this.props.onClick();
            }}
          >
            {this.renderContent()}
          </MobileLink>
        )}
      </Header>
    );
  }
}
