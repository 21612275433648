import {
  TracksAzCapiMessage,
  FavoriteTracksMessage,
  TracksEmptyMessageInfo,
  RacingScheduleConfigMessage,
  SupportedFilters,
  HorseNotFoundInfo
} from "../types";

export const tracksAzInfo: TracksAzCapiMessage = {
  title: "Tracks A-Z",
  resultedTitle: "Resulted races"
};

export const favoriteTracksInfo: FavoriteTracksMessage = {
  title: "Favorites",
  noFavorites: {
    title: "No Favorites Added",
    hint: "Use the star to create a shortcut"
  }
};

export const tracksEmptyMessage: TracksEmptyMessageInfo = {
  bySearch: {
    tracks: {
      title: "No races",
      hint: "Make sure to check your spelling."
    },
    results: {
      title: "No races",
      hint: "Make sure to check your spelling."
    }
  },
  byFilters: {
    title: "No races",
    hint: "Your filters hid them. Clear your filters and try again."
  },
  noRaces: {
    title: "No races available",
    hint: "This is not that common, check back soon."
  }
};

export const seoTracksPage = {
  title: "Horse Racing Betting",
  content: "Message"
};

export const defaultFiltersConfig: RacingScheduleConfigMessage[] = [
  {
    id: SupportedFilters.RACE_TYPE,
    label: "Race Type",
    visible: true
  },
  {
    id: SupportedFilters.REGION,
    label: "Region",
    visible: true
  },
  {
    id: SupportedFilters.TRACK_TYPE,
    label: "Tracks",
    visible: true
  },
  {
    id: SupportedFilters.BETTING_FEATURE,
    label: "Races",
    visible: true
  },
  {
    id: SupportedFilters.DISTANCE,
    label: "Distance",
    visible: true
  }
];

export const horseNotFoundInfo: HorseNotFoundInfo = {
  dialogMessage: {
    title: "Couldn't find",
    description: "Make sure to check your spelling."
  },
  warningMessage: {
    minLength: "Min of 3 characters",
    notFound: "No horses found"
  }
};
