// @flow

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

const panelLinkCSS = ` text-decoration: none;
  width: 100%;`;

export const PanelLink = styled(Link)`
  ${panelLinkCSS}
`;
export const PanelLinkTVG4 = styled.div`
  ${panelLinkCSS}

  &:hover {
    cursor: pointer;
  }
`;

export const PanelContainer = styled.div`
  height: ${({ isUpcoming }) => (isUpcoming ? "110px" : "112px")};
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${buildColor("blue_accent", "000")};

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const Country = styled.span`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 12px;
  height: 14px;
  text-transform: uppercase;
  text-align: right;
  margin-left: 4px;
`;

export const InformationTitle = styled.div`
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
`;

export const TrackName = styled.span`
  color: ${(props) =>
    props.highlightedStyle === "Highlighted"
      ? buildColor("blue", "500")
      : buildColor("grey", "900")};
  font-family: ${fontMedium};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 2px;
  display: block;
`;

export const MetaDescript = styled.span`
  ${(props) =>
    props.highlightedStyle && props.highlightedStyle === "Highlighted"
      ? css`
          color: ${buildColor("grey", "900")};
          font-family: ${fontMedium};
        `
      : css`
          margin-top: 2px;
          color: ${buildColor("grey", "800")};
          font-family: ${fontMedium};
          text-transform: uppercase;
          font-size: 12px;
        `}
  display: block;
  text-overflow: ellipsis;
  overflow: auto;
  white-space: nowrap;
`;

export const RaceTitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

export const TrackNameWrapper = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: ${fontMedium};
`;

export const RaceNumberWrapper = styled.span`
  align-self: flex-end;
  font-family: ${fontMedium};
`;

export const InformationWrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TagsWrapper = styled.div`
  display: flex;

  & > div {
    ${({ isCollapsed }) =>
      isCollapsed
        ? css`
            margin-left: -2px;
            margin-right: -4px;
            border: 2px solid ${buildColor("blue_accent", "000")};
          `
        : css`
            margin-right: 2px;
          `}
  }
`;

export const TagWrapper = styled.div`
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      border-radius: 50%;
      z-index: ${({ zIndex }) => zIndex || 100};

      & > div > svg {
        padding: 4px;
      }
    `}
`;

export const GreyhoundIcon = styled(Icon)`
  && {
    width: 15px;
  }
`;

export const CountryInformation = styled.div`
  display: flex;
  align-self: center;
  margin-left: auto;
`;

export const OnTVG = styled.div`
  display: flex;
  align-items: center;
`;

export const PanelFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 28px;
  margin-bottom: -3px;
`;
