import { useState, useRef, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { Race, PastRaceResults, RaceStatusEnum } from "@tvg/ts-types/Race";
import { calculateVisibleElements, getWidthCells } from "../utils";

type InitialScrollBehavior = "first" | "next-race";

interface ScrollState {
  activeIdx: number;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
}

export const useRaceScroll = (
  races: Array<Race | PastRaceResults> | undefined,
  isOpen: boolean,
  initialBehavior: InitialScrollBehavior = "next-race"
) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isInitialScroll = useRef(true);
  const [scrollState, setScrollState] = useState<ScrollState>({
    activeIdx: 0,
    isNextEnabled: false,
    isPrevEnabled: false
  });

  const visibleItems = calculateVisibleElements(containerRef.current);

  const scrollTo = useCallback(
    (index: number, behavior: ScrollBehavior = "smooth") => {
      const container = containerRef.current;
      if (!container) return;

      const targetScroll = getWidthCells(index, container.childNodes, 8);
      container.scrollTo({ left: targetScroll, behavior });
    },
    []
  );

  const updateScrollState = useCallback(() => {
    const container = containerRef.current;
    if (!container || !races?.length) return;

    const cellWidth = container.scrollWidth / races.length;
    const newActiveIdx = Math.round(container.scrollLeft / cellWidth);

    setScrollState({
      activeIdx: newActiveIdx,
      isNextEnabled:
        container.scrollLeft + container.clientWidth < container.scrollWidth,
      isPrevEnabled:
        newActiveIdx > 0 && container.clientWidth < container.scrollWidth
    });
  }, [races?.length]);

  // Initial scroll setup
  useEffect(() => {
    if (isOpen && isInitialScroll.current && races?.length) {
      const initialIndex =
        initialBehavior === "first"
          ? 0
          : races.findIndex(
              (race) =>
                "status" in race &&
                [RaceStatusEnum.OPEN, RaceStatusEnum.UP_NEXT].includes(
                  race.status.code
                )
            ) ?? 0;

      setTimeout(() => {
        scrollTo(
          initialIndex >= 0 ? initialIndex : races.length - visibleItems
        );
        updateScrollState();
        isInitialScroll.current = false;
      }, 500);
    }

    if (!isOpen) {
      isInitialScroll.current = true;
      setScrollState({
        activeIdx: 0,
        isNextEnabled: false,
        isPrevEnabled: false
      });
    }
  }, [isOpen, races, initialBehavior, scrollTo]);

  // Scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    const debouncedUpdate = debounce(updateScrollState, 100);

    if (container && container.clientWidth < container.scrollWidth) {
      container.addEventListener("scroll", debouncedUpdate);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", debouncedUpdate);
      }
      debouncedUpdate.cancel();
    };
  }, [updateScrollState]);

  const handleScroll = (direction: "prev" | "next") => {
    const newIdx =
      direction === "prev"
        ? Math.max(0, scrollState.activeIdx - visibleItems)
        : scrollState.activeIdx + visibleItems;

    scrollTo(newIdx);
  };

  return {
    containerRef,
    activeIdx: scrollState.activeIdx,
    isNextEnabled: scrollState.isNextEnabled,
    isPrevEnabled: scrollState.isPrevEnabled,
    handleScroll,
    scrollTo
  };
};
