import React from "react";
import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { Runner } from "../../types";
import { LABELS } from "../../constants";
import { RunnerItem } from "../RunnerItem";
import { RunnerListContainer } from "./styled-components";

interface RunnerListProps {
  raceTypeCode: RaceTypeCodeEnum;
  runners: Runner[];
  runnerNameHighlight?: string;
  showRunners: boolean;
}

export const RunnerList: React.FC<RunnerListProps> = ({
  raceTypeCode,
  runners,
  runnerNameHighlight,
  showRunners
}) => (
  <RunnerListContainer
    showRunners={showRunners}
    aria-hidden={!showRunners}
    role="list"
    aria-label={LABELS.RUNNERS_LIST}
  >
    {runners.map((runner) => (
      <RunnerItem
        key={runner.saddlecloth}
        raceTypeCode={raceTypeCode}
        runner={runner}
        isHighlight={runner.name === runnerNameHighlight}
      />
    ))}
  </RunnerListContainer>
);

export default RunnerList;
