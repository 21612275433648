// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontMedium, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";
import MTP from "../../_atom/MTP";

const styleLink = css`
  text-decoration: none;
  text-transform: none;
  font-family: ${fontMedium};
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  display: flex;
`;

export const DesktopPoolLink = styled.a`
  ${styleLink}
  width: 100%;
  height: auto;
  flex-flow: row nowrap;
  padding: 16px 0;

  &:hover {
    cursor: pointer;
  }
`;

export const MobilePoolLink = styled(Link)`
  ${styleLink}
  width: 142px;
  height: 112px;
  padding: 12px;
  flex-flow: column nowrap;
  justify-content: space-between;
  background: ${buildColor("blue_accent", "000")};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
  border: 1px solid ${buildColor("blue", "100")};

  &:active {
    background: ${buildColor("blue_accent", "100")};
    box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
    border: 1px solid ${buildColor("blue", "100")};
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.$isPinned &&
    css`
      background: ${buildColor("green", "000")};
      box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
      border: 1px solid ${buildColor("green", "400")};

      &:active {
        background: ${buildColor("green", "100")};
        border: 1px solid ${buildColor("green", "400")};
      }
    `}

  ${(props) =>
    (props.$isPinned || props.$isFeatured) &&
    css`
      width: 197px;
    `}
`;

export const RaceContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const PoolContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const TrackRace = styled.div.attrs((props) => ({
  "data-qa-label": props.isFeatured ? "featuredTrackRace" : "trackRace"
}))`
  display: flex;
  flex-flow: row nowrap;
  font-weight: 500;
  color: ${buildColor("grey", "900")};
  ${(props) =>
    props.isFeatured &&
    css`
      color: ${buildColor("blue", "500")};
    `}
`;

export const Track = styled.span`
  font-family: ${fontMedium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Race = styled.span`
  font-family: ${fontMedium};
  text-transform: uppercase;
  margin-left: 4px;
  flex: 0 0 auto;
`;

export const Pool = styled.span.attrs({
  "data-qa-label": "poolsAmount"
})`
  font-weight: 500;
  font-family: ${fontMedium};
  color: ${buildColor("green", "600")};
  font-size: 20px;
  ${(props) =>
    props.isDesktop &&
    css`
      flex: 0 0 60px;
      align-self: center;
      text-align: right;
    `}
`;

export const WagerType = styled.span.attrs({
  "data-qa-label": "wagerType"
})`
  font-family: ${fontNormal};
  color: ${(props) =>
    props.isDesktop ? buildColor("grey", "800") : buildColor("green", "600")};
`;

export const RacePool = styled.div`
  padding: 0 8px;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow: hidden;
`;

export const RaceMTP = styled(MTP)`
  ${(props) =>
    props.isDesktop &&
    css`
      flex: 0 0 44px;
    `}
`;
