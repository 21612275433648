// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type TalentPicksGTMTypeEvents =
  | "TP_TPPAGE_EDIT_BET"
  | "TP_PROGRAMPAGE_EDIT_BET"
  | "TP_TPPAGE_BET"
  | "TP_PROGRAMPAGE_BET"
  | "TP_PROGRAMPAGE_BET_SUCCESS"
  | "TP_PROGRAMPAGE_BET_ERROR"
  | "TP_PROGRAMPAGE_TPTAB"
  | "TP_HOMEPAGE_BANNER"
  | "TP_PROGRAMPAGE_CONFIRM_BET_OK"
  | "TP_PROGRAMPAGE_CONFIRM_BET_CLOSE"
  | "TP_PROGRAMPAGE_CONFIRM_PHRASE"
  | "TP_TALENT_CLICK";

type TalentPicksGTMType = {
  type: TalentPicksGTMTypeEvents,
  payload: {
    tag?: string,
    action?: string,
    label?: string,
    race?: {
      mtp?: number,
      raceNumber: string,
      track: {
        trackName: string
      },
      type: {
        code: string
      }
    },
    betType?: string,
    betAmount?: number,
    url?: string,
    betId?: string | number,
    talentPickOrigin?: ?("pickspage" | "programpage"),
    talentFilterState?: "Toggle ON" | "Toggle Off",
    talentName?: string
  }
};

const getSport = (type) =>
  type === "G" ? "Greyhounds Racing" : "Horse Racing";

const getPageModule = (origin) =>
  origin === "pickspage" ? "Talent Pick Page" : "Talent Pick Homepage";

const getModule = (origin) =>
  origin === "pickspage" ? "Talent Pick Page" : "PP Trackmaster Picks";

export default () => {
  // https://betfairus.atlassian.net/browse/TP-81
  mediatorChannels.base.subscribe(
    "TP_TPPAGE_BET",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        gaEventValue: undefined,
        module: getPageModule(get(data, "payload.module", "pickspage")),
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-108
  mediatorChannels.base.subscribe(
    "TP_TPPAGE_EDIT_BET",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        module: getPageModule(get(data, "payload.module", "pickspage")),
        gaEventValue: undefined,
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: undefined,
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-107
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_EDIT_BET",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        module: "PP Trackmaster Picks",
        gaEventValue: undefined,
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: undefined,
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-71
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_BET",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        module: "PP Trackmaster Picks",
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-74
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_BET_SUCCESS",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        gaEventValue: get(data, "payload.betAmount"),
        module: getModule(get(data, "payload.talentPickOrigin")),
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: get(data, "payload.betId"),
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-75
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_BET_ERROR",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        module: getModule(get(data, "payload.talentPickOrigin")),
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-70
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_TPTAB",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: get(data, "payload.label"),
        module: "PP Trackmaster Picks",
        tag: get(data, "payload.tag"),
        microApp: "non-Microapp"
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-69
  mediatorChannels.base.subscribe(
    "TP_HOMEPAGE_BANNER",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        menu: undefined,
        module: "Home Page Banner",
        sport: undefined,
        microApp: "non-Microapp",
        tag: undefined,
        destinationUrl: get(data, "payload.url")
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-72
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_CONFIRM_BET_OK",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        module: getModule(get(data, "payload.talentPickOrigin")),
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-73
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_CONFIRM_BET_CLOSE",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        module: "PP Trackmaster Picks",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-104
  mediatorChannels.base.subscribe(
    "TP_PROGRAMPAGE_CONFIRM_PHRASE",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.label"),
        gaEventValue: undefined,
        module: getModule(get(data, "payload.talentPickOrigin")),
        runnerSelectionList: get(data, "payload.tag"),
        trackName: get(data, "payload.race.track.trackName"),
        raceNumber: get(data, "payload.race.raceNumber"),
        betId: undefined,
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: getSport(get(data, "payload.race.type.code")),
        repeatBet: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-213
  mediatorChannels.base.subscribe(
    "TP_TALENT_CLICK",
    (data: TalentPicksGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.talentFilterState"),
        gaEventLabel: "Talent Picks",
        module: "Talent Picks",
        tag: get(data, "payload.talentName"),
        microApp: undefined
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-214
  mediatorChannels.base.subscribe("TP_ALERTS_CLICK", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Alerts",
      gaEventLabel: undefined,
      module: "Talent Picks",
      tag: undefined,
      microApp: undefined
    });
  });

  // https://betfairus.atlassian.net/browse/HP-518

  mediatorChannels.base.subscribe("HP_TP_VIEW_RACE", ({ payload }) => {
    const { clickLocation, talentPick, url } = payload;
    const { trackName, race, mtp, talent, raceType } = talentPick;
    const betType = get(talentPick, "wagerType.abbreviation", "WN");
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: `${trackName} - R${race} - ${mtp} - ${talent.name}`,
      module: `talent picks - ${clickLocation}`,
      tag: betType,
      menu: undefined,
      sport: raceType === "G" ? "Greyhounds Racing" : "Horse Racing",
      destinationUrl: url
    });
  });

  // https://betfairus.atlassian.net/browse/HP-518

  mediatorChannels.base.subscribe("TALENT_PICKS_SEE_NEXT_RACES", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Next",
      gaEventLabel: undefined,
      module: "Talent Pick Homepage",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("TALENT_PICKS_SEE_PREVIOUS_RACES", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Back",
      gaEventLabel: undefined,
      module: "Talent Pick Homepage",
      tag: undefined,
      microApp: undefined
    });
  });
};
