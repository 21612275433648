// @flow
import type { Promo } from "@tvg/types/Promos";

export type SetPromosListType = {
  type: "PROMOS_LIST_SET",
  payload: Promo[]
};

export type SetPromoWidgetModalStateAction = {
  type: "SET_PROMO_WIDGET_MODAL_STATE",
  payload: boolean
};

export default (userPromos: Promo[]): SetPromosListType => ({
  type: "PROMOS_LIST_SET",
  payload: userPromos
});

export const setPromoWidgetModalStateAction = (
  value: boolean
): SetPromoWidgetModalStateAction => ({
  type: "SET_PROMO_WIDGET_MODAL_STATE",
  payload: value
});
