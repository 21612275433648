import styled, { css } from "styled-components";

export const WinnersList = styled.ul<{
  isDesktop: boolean;
  isModal: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  flex-flow: row nowrap;
  list-style: none;
  ${({ theme, isDesktop, isModal }) =>
    isDesktop || isModal
      ? css`
          flex-flow: column;
        `
      : css`
          padding: 0 ${theme.space["space-5"]} ${theme.space["space-4"]};
          gap: ${theme.space["space-4"]};
        `};
`;

export const ListItem = styled.li<{
  isDesktop: boolean;
  isModal: boolean;
}>`
  width: 100%;
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  padding: ${({ theme }) => theme.space["space-5"]};
  ${({ theme, isDesktop, isModal }) =>
    isDesktop || isModal
      ? css`
          border-bottom: 1px solid ${theme.colorTokens.border.default};
        `
      : css`
          border: 1px solid ${theme.colorTokens.border.default};
          border-radius: ${theme.radii.s};
        `};
  ${({ theme, isDesktop }) =>
    isDesktop &&
    css`
      &:last-child {
        border: none;
      }

      &:hover {
        background-color: ${theme.colors.blue_accent[100]};
      }
    `};
`;

export const LastElement = styled.li`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    css`
      background-color: ${theme.colorTokens.background.surface};
      padding: ${theme.space["space-3"]} ${theme.space["space-6"]};
    `}
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  ${({ theme }) =>
    css`
      margin: 0 0 ${theme.space["space-6"]};
      background-color: ${theme.colorTokens.background.surface};
      border: 1px solid
        ${theme.colorTokens.component.button.tertiary.content.base};
    `}
`;
