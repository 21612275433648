import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ theme }) =>
    css`
      margin-bottom: ${theme.space["space-4"]};
      border-radius: ${theme.radii.s};
      background: ${theme.colorTokens.background.surface};
    `};
`;

export const CollapsibleWrapperStyled = styled.div`
  & > div:last-of-type {
    ${({ theme }) =>
      css`
        border-bottom: none;
        border-bottom-left-radius: ${theme.radii.s};
        border-bottom-right-radius: ${theme.radii.s};
      `};
  }
`;

export const UpperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  ${({ theme }) =>
    css`
      padding: ${theme.space["space-4"]} ${theme.space["space-3"]};
    `};
`;

export const LinkContainer = styled(Link)`
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.medium};
      font-size: ${theme.fontSizes.s};
      color: ${theme.colorTokens.component.button.buttonLink.content.base};
      padding: ${theme.space["space-3"]};
    `};
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
