// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import type { RacePanelLink } from "@tvg/types/Race";
import pushToDataLayer, { processTrackName } from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("TOP_RACES_SEE_NEXT_RACES", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Next",
      gaEventLabel: undefined,
      module: "Top Races",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("TOP_RACES_SEE_PREVIOUS_RACES", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Back",
      gaEventLabel: undefined,
      module: "Top Races",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "TOP_RACES:SELECT_RUNNER",
    (data: {
      type: "TOP_RACES:SELECT_RUNNER",
      payload: {
        race: RacePanelLink,
        tag: string,
        url: string,
        runner: string
      }
    }) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: `${processTrackName(
          get(data, "payload.race.trackName")
        )} - R${get(data, "payload.race.raceNumber")} - ${get(
          data,
          "payload.race.mtp"
        )}`,
        module: "Top races - runner",
        tag: `${get(data, "payload.tag")} - ${get(data, "payload.runner")}`,
        menu: undefined,
        sport: get(data, "payload.race.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing",
        destinationUrl: get(data, "payload.url")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "HEADER_GO_TO_RACE",
    (data: {
      type: "HEADER_GO_TO_RACE",
      payload: {
        section: string,
        tag: string,
        url: string,
        race: RacePanelLink
      }
    }) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: `${processTrackName(
          get(data, "payload.race.trackName")
        )} - R${get(data, "payload.race.raceNumber")} - ${get(
          data,
          "payload.race.mtp"
        )}`,
        module: `Top races - ${get(data, "payload.section")}`,
        tag: `${get(data, "payload.tag")}`,
        menu: undefined,
        sport: get(data, "payload.race.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing",
        destinationUrl: get(data, "payload.url")
      });
    }
  );
};
