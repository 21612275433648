type ToggleOpenTrackRow = {
  type: "TOGGLE_TRACK_ROW";
  payload: {
    openTracks: Array<string>;
  };
};

type ToggleReplayVideo = {
  type: "SET_CURRENT_REPLAY_VIDEO" | "REMOVE_CURRENT_REPLAY_VIDEO";
  payload: {
    currentReplay: string | null;
  };
};

export type Actions = ToggleOpenTrackRow | ToggleReplayVideo;

export const toggleOpenTrackRow = (
  openTracks: Array<string>
): ToggleOpenTrackRow => ({
  type: "TOGGLE_TRACK_ROW",
  payload: { openTracks }
});

export const setCurrentReplayVideo = (
  currentReplay: string | null
): ToggleReplayVideo => ({
  type: "SET_CURRENT_REPLAY_VIDEO",
  payload: { currentReplay }
});
