import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import { get, noop } from "lodash";
import { RaceResult } from "@tvg/design-system/web";
import { openMybetsPastPerformance } from "@tvg/sh-lib-my-bets/redux/actions";
import {
  getHideResults,
  getCurrentReplayVideo,
  getRaceReplayOpenInline
} from "@urp/lib-racetracks/src/redux/selectors";
import { ToggleHideResultsEnum } from "@urp/lib-racetracks/src/types";
import { getIsLogged } from "@urp/store-selectors";
import { openLoginWall } from "@tvg/sh-utils/sessionUtils";
import buildRaceUrl from "@tvg/formatter/url";
import { MyBetsPastPerformanceTab } from "@tvg/sh-lib-my-bets/utils/types";
import {
  formatDateToMMDDYYYYhhmm,
  formatPastRaceDate
} from "@tvg/formatter/dates";
import { usePastRace } from "@urp/lib-racetracks";
import {
  sendReplayVideoClickedAnalyticEvt,
  sendViewResultsAnalyticEvt
} from "@urp/lib-racetracks/src/utils/analytics";
import { setCurrentReplayVideo } from "@urp/lib-racetracks/src/redux/actions/tracks";
import VideoPlayer from "@urp/video-player";

// Types
import type { Race, RaceTypeCodeEnum, Results } from "@tvg/ts-types/Race";
import type { Runner } from "@tvg/design-system/web/components/RaceResult/types";
import type { RaceResultCardProps } from "./types";

import { getPayoutHeaders } from "../../utils";
import { MAX_RUNNERS_RESULTS } from "../../constants";
import { ResultRacecardWrapper } from "./styled-components";
import { getResultRaceRunners } from "./utils";

const RaceResultCard: FC<RaceResultCardProps> = ({
  dispatch,
  race,
  runnerNameHighlight,
  isGrouped = false,
  isButtonsHidden = false,
  isTrackNameShown = false,
  betsCounter,
  showFullNameOnReplayVideo = false,
  onMyBetsClick = noop
}) => {
  const cardRef = useRef<HTMLSpanElement>(null);
  const currentReplayVideo = useSelector(getCurrentReplayVideo);
  const hideResults = useSelector(getHideResults);
  const [showResults, setShowResults] = useState<boolean>(
    hideResults !== ToggleHideResultsEnum.ON
  );
  const raceReplayOpenInline = useSelector(getRaceReplayOpenInline);

  const isLogged = useSelector(getIsLogged);
  const [runners, setRunners] = useState<Runner[]>([]);

  // TODO: This hook it's gonna be removed when results info is available on list.
  const { firstPastRace, isLoading } = usePastRace({
    date: formatPastRaceDate(get(race, "date") ?? new Date()),
    trackCode: race.track.code,
    raceNumber: race.number
  });

  const checkIsLoggedIn = useCallback(
    (cb: () => void) => (isLogged ? cb() : openLoginWall(cb)),
    [isLogged]
  );

  useEffect(() => {
    const results: Results = get(firstPastRace, "results");

    if (runners?.length === 0) {
      setRunners(getResultRaceRunners(results?.runners));
    }
  }, [isLoading, firstPastRace]);

  useEffect(() => {
    setShowResults(hideResults !== ToggleHideResultsEnum.ON);
    dispatch(setCurrentReplayVideo(null));
  }, [hideResults]);

  const handleSeeResults = () => {
    //  If the date property is not available (current day - pastRace type Race), it falls back to using the postTime property
    const dateFilter = formatPastRaceDate(
      get(firstPastRace, "date") ?? firstPastRace?.postTime
    );

    dispatch(
      openMybetsPastPerformance(
        {
          trackCode: firstPastRace.track.code,
          raceNumber: Number(firstPastRace.number),
          raceDate: dateFilter as string
        },
        `${firstPastRace.track.name} R${firstPastRace.number} - Replay`,
        hideResults === ToggleHideResultsEnum.ON
          ? MyBetsPastPerformanceTab.RACECARD
          : MyBetsPastPerformanceTab.FULL_RESULT
      )
    );
  };

  const trackRace = `${race.track.code + race.number}`;
  const handleWatchReplay = () => {
    if (raceReplayOpenInline) {
      dispatch(setCurrentReplayVideo(firstPastRace.id));
    } else {
      handleSeeResults();
    }
  };

  const raceUrl = useMemo(
    () => buildRaceUrl(race.track.code, race.track.name, +race.number),
    [race]
  );

  const handleClickOnReplayButton = () => {
    checkIsLoggedIn(handleWatchReplay);
    sendReplayVideoClickedAnalyticEvt({
      trackName: race.track.name,
      raceNumber: race.number,
      linkUrl: raceUrl,
      trackCountry: (race as Race)?.track?.location?.country || "",
      hideResultsSpoiler:
        hideResults === ToggleHideResultsEnum.ON ? "on" : "off"
    });
  };

  const trackViewResultsEvt = () => {
    sendViewResultsAnalyticEvt({
      trackName: race.track.name,
      raceNumber: race.number,
      linkUrl: raceUrl,
      trackCountry: (race as Race)?.track?.location?.country || "",
      hideResultsSpoiler:
        hideResults === ToggleHideResultsEnum.ON ? "on" : "off"
    });
  };

  return (
    <ResultRacecardWrapper ref={cardRef}>
      {firstPastRace?.id === currentReplayVideo ? (
        <VideoPlayer
          qaLabel="replay-video-tracks-results"
          replayFile={firstPastRace?.video.replayFileName}
          width={cardRef.current?.scrollWidth}
          height={cardRef.current?.scrollHeight}
          onClose={() => dispatch(setCurrentReplayVideo(null))}
          title={
            showFullNameOnReplayVideo
              ? `${race.track.code} - R${race.number}`
              : `Race ${race.number}`
          }
          isAutoPlay
        />
      ) : (
        <RaceResult
          key={trackRace}
          time={formatDateToMMDDYYYYhhmm(race.postTime)}
          raceNumber={+race.number}
          raceTypeCode={race.type.code as RaceTypeCodeEnum}
          trackName={isTrackNameShown ? race.track.name : ""}
          runnerNameHighlight={runnerNameHighlight}
          runners={runners}
          betsCounter={betsCounter}
          payoutHeaders={getPayoutHeaders(runners, MAX_RUNNERS_RESULTS)}
          showRunners={showResults}
          isReplayAvailable={Boolean(firstPastRace?.video?.replayFileName)}
          isLoading={isLoading}
          isGrouped={isGrouped}
          hideButtons={isButtonsHidden}
          onSeeResultClick={() => {
            trackViewResultsEvt();
            checkIsLoggedIn(handleSeeResults);
          }}
          onRevealClick={() => {
            trackViewResultsEvt();
            setShowResults(true);
          }}
          onWatchReplayClick={handleClickOnReplayButton}
          onMyBetsClick={onMyBetsClick}
        />
      )}
    </ResultRacecardWrapper>
  );
};

export default RaceResultCard;
