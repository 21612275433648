// @flow
import React, { Component, type Element } from "react";
import { noop, isEqual, indexOf, toString, get } from "lodash";
import type {
  BinaryFn,
  UnaryFn,
  TernaryFn,
  QuadFn
} from "@tvg/types/Functional";
import { doesSilkLoads } from "@tvg/utils/handicappingUtils";
import type {
  RaceCardBettingInterest,
  RaceTypeCodeEnum,
  RaceCardRunner,
  ExpandLineArgs,
  HandicapSortOptionsType,
  RacePanelLink,
  RaceProgram
} from "@tvg/types/Race";
import type { HandicappingRunnerFlags } from "@tvg/ipp/src/components/RunnerFlags";
import RunnerLine from "../../_molecule/RunnerLine";

export type Selections = string[][];

type Props = {
  runners: RaceCardBettingInterest[],
  /**
   * Leg number for the race
   */
  leg: number,
  /**
   * Flag to activate the leg layout
   */
  isLeg: boolean,
  /**
   * Flag that controls if the race is wagerable or not
   */
  isWagerable: boolean,
  /**
   * Race type code
   */
  raceTypeCode: RaceTypeCodeEnum,
  /**
   * Array with the current selections of the RaceCard
   */
  selections: Selections,
  /**
   * Flag to activate a key column
   */
  isKey: boolean,
  /**
   * Number of selection columns
   */
  numColumns: number,
  /**
   * Calback to run when user selects a runner
   */
  onRunnerSelect: BinaryFn<string, number, mixed>,
  /**
   * For the first element of inline handicapping be bold
   */
  firstHandicapBold?: boolean,
  /**
   **************** talent pick tool props *******************
   */
  /**
   * Activate favorite selection
   */
  isFavoriteSelectionActive: boolean,
  /**
   * Selected the favorite
   */
  favoriteSelections: string[][],
  /**
   * Runner Line expanded
   */
  expandedRunner: string,
  /**
   * Runner Line expand callBack
   */
  expandLine: UnaryFn<ExpandLineArgs, void>,
  /**
   * Render Expanded info
   */
  expandInfo: QuadFn<
    string,
    number,
    RaceCardBettingInterest[],
    string,
    Element<*> | void
  >,
  /**
    Arrow direction to use on inline pp
   */
  inLinePastPerformance: boolean,
  /**
    is track master pick
   */
  isTrackMasterPick: boolean,
  /**
    is numberFire pick
   */
  isNumberFirePick: boolean,
  /**
   is Racing And Sports
   */
  isRacingAndSports: boolean,
  /**
   Handicapping Sort Mode
   */
  handicappingSortMode: HandicapSortOptionsType,
  /**
   Reveals if the user may see the inLinePastPerformance or be redirected to the race card on the respective runnerLines
  */
  redirectToRaceCard: boolean,
  /**
   The race url to be redirected to
  */
  raceUrl: string,
  /**
   * Has TrackMaster information
   */
  hasTrackMasterInfo: boolean,
  /**
   * On runner selection for top races
   */
  onRunnerSelection: TernaryFn<RacePanelLink, number, string, void>,
  /**
   * The Race this runner list is on
   */
  race: ?RacePanelLink,
  /**
   * hover effect on runner bio
   *
   */
  hasHover: boolean,
  /**
   * Show runner line active state
   */
  showRunnerLineActiveState: boolean,
  /**
   * Track data source
   */
  trackDataSource: string,
  /**
   * Current Race info
   */
  currentRace: RaceProgram,
  handicappingRunnerFlags: HandicappingRunnerFlags,
  scrollRunnerPP: boolean,
  scrollOffset: number
};

type State = {
  doesSilkLoads: boolean
};

const getRunnerSelections = (biNumber: number, selections: Selections) =>
  selections.map((column) => indexOf(column, toString(biNumber)) >= 0);

const getFavoriteSelections = (biNumber: number, leg: string[]) =>
  leg.indexOf(`${biNumber}`) >= 0;

export default class RunnerList extends Component<Props, State> {
  static defaultProps = {
    runners: [],
    leg: 0,
    isLeg: false,
    isWagerable: true,
    onRunnerSelect: noop,
    raceTypeCode: "T",
    selections: [[]],
    numColumns: 1,
    isKey: false,
    firstHandicapBold: false,
    isFavoriteSelectionActive: false,
    favoriteSelections: [[]],
    expandedRunner: "",
    expandLine: noop,
    expandInfo: noop,
    inLinePastPerformance: false,
    isTrackMasterPick: false,
    isNumberFirePick: false,
    isRacingAndSports: false,
    handicappingSortMode: "saddlecloth",
    redirectToRaceCard: false,
    raceUrl: "/",
    hasTrackMasterInfo: true,
    onRunnerSelection: noop,
    race: null,
    hasHover: false,
    showRunnerLineActiveState: true,
    trackDataSource: "",
    currentRace: {},
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    scrollRunnerPP: false,
    scrollOffset: 0
  };

  state: State = {
    doesSilkLoads: true
  };

  componentDidMount() {
    this.setDoesSilkLoads();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.state.doesSilkLoads !== nextState.doesSilkLoads ||
      !isEqual(nextProps, this.props)
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.currentRace, prevProps.currentRace)) {
      this.setDoesSilkLoads();
    }
  }

  setDoesSilkLoads() {
    doesSilkLoads(this.props.runners).then((silkLoads) => {
      if (this.state.doesSilkLoads !== silkLoads) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          doesSilkLoads: silkLoads
        });
      }
    });
  }

  renderRunners() {
    return (
      this.props.runners &&
      this.props.runners.map((bi: RaceCardBettingInterest) =>
        bi.runners.map((runner: RaceCardRunner) => (
          <RunnerLine
            race={this.props.race}
            runners={this.props.runners}
            key={`runner-${runner.runnerId}`}
            selections={getRunnerSelections(bi.biNumber, this.props.selections)}
            runnerData={runner}
            isFavorite={bi.isFavorite}
            biNumber={bi.biNumber}
            leg={this.props.leg}
            raceTypeCode={this.props.raceTypeCode}
            currentOddsNumerator={get(bi, "currentOdds.numerator")}
            currentOddsDenominator={get(bi, "currentOdds.denominator")}
            morningLineOddsNumerator={get(bi, "morningLineOdds.numerator")}
            morningLineOddsDenominator={get(bi, "morningLineOdds.denominator")}
            isWagerable={this.props.isWagerable}
            onRunnerSelect={this.props.onRunnerSelect}
            numColumns={this.props.numColumns}
            isKey={this.props.isKey}
            isLeg={this.props.isLeg}
            expandedRunner={this.props.expandedRunner}
            expandLine={this.props.expandLine}
            expandInfo={this.props.expandInfo}
            firstHandicapBold={this.props.firstHandicapBold}
            isFavoriteSelectionActive={this.props.isFavoriteSelectionActive}
            onRunnerSelection={this.props.onRunnerSelection}
            favoriteSelection={
              this.props.isFavoriteSelectionActive &&
              getFavoriteSelections(
                bi.biNumber,
                this.props.favoriteSelections[this.props.leg]
              )
            }
            inLinePastPerformance={this.props.inLinePastPerformance}
            isRunnerTrackMasterPick={this.props.isTrackMasterPick}
            isRunnerNumberFirePick={this.props.isNumberFirePick}
            isRunnerRacingAndSports={this.props.isRacingAndSports}
            handicappingSortMode={this.props.handicappingSortMode}
            redirectToRaceCard={this.props.redirectToRaceCard}
            raceUrl={this.props.raceUrl}
            hasTrackMasterInfo={this.props.hasTrackMasterInfo}
            hasHover={this.props.hasHover}
            showActiveState={this.props.showRunnerLineActiveState}
            trackDataSource={this.props.trackDataSource}
            currentRace={this.props.currentRace}
            handicappingRunnerFlags={this.props.handicappingRunnerFlags}
            scrollRunnerPP={this.props.scrollRunnerPP}
            scrollOffset={this.props.scrollOffset}
            doesSilkLoads={this.state.doesSilkLoads}
          />
        ))
      )
    );
  }

  render() {
    return !!this.props.runners.length && <tbody>{this.renderRunners()}</tbody>;
  }
}
