// @flow
import type { OperationComponent } from "@apollo/client";
import { get } from "lodash";
import type { Track } from "@tvg/types/Track";
import type { WagerProfile } from "@tvg/types/User";
import tvgConf from "@tvg/conf";
import { getPortByBrand } from "@tvg/utils/generalUtils";

type ApolloProps = {
  wagerProfile: WagerProfile,
  accountId: string,
  isLogged: boolean,
  rdaClient: mixed,
  isAccountCompliant: boolean
};

const QUERY_VARIABLES = {
  wagerProfile: "PORT-Generic",
  accountId: ""
};

const POLL_INTERVAL = 60000;

const loggedInOptions = (props: ApolloProps) => ({
  ...QUERY_VARIABLES,
  accountId: +get(props, "accountId", "-1"),
  wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
  product: tvgConf().product,
  brand: tvgConf().brand
});

const loggedOutOptions = (props: ApolloProps) => ({
  wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
  product: tvgConf().product,
  brand: tvgConf().brand
});

type ParsedTopTrack = {
  name: string,
  shortName: string,
  code: string,
  isFavorite: boolean,
  hasPromo: boolean,
  isFinished: boolean,
  favorite: boolean,
  isGreyhound: boolean,
  isOpen: boolean,
  isPromoTagShown: boolean
};

// TODO: Import CombinedHeaderTracksActions
export const topTracksDataFeeder = (tracks: Track[] = []): ParsedTopTrack[] =>
  tracks.map((track: Track): any => ({
    name: track.name,
    shortName: track.shortName,
    code: track.code,
    isFavorite: track.isFavorite,
    hasPromo: track.hasAboveTheLinePromo || track.hasPromo,
    isFinished: track.isFinished,
    favorite: track.favorite,
    isGreyhound: track.isGreyhound,
    isOpen: false,
    isPromoTagShown: get(track, "isPromoTagShown", false)
  }));

export default {
  options: (props: ApolloProps) => {
    const variables = {
      ...(props.isLogged ? loggedInOptions(props) : loggedOutOptions(props))
    };
    return {
      client:
        props.isAccountCompliant && !!get(props, "accountId")
          ? props.rdaClient
          : null,
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: true,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({
    tracks: topTracksDataFeeder(get(result, "data.topTracks", [])),
    featuredTracks: topTracksDataFeeder(get(result, "data.featureTracks", [])),
    isLoading: get(result, "data.loading", false),
    refetch: result.data.refetch
  })
};
