// @flow

import styled, { css } from "styled-components";
import { fontBold, fontMedium, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Title = styled.h1`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  margin: 0 12px;
  height: 19px;
  font-size: 16px;
`;

export const UpperSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 14px 0;
`;

export const EmptyStateMessage = styled.p`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  height: 48px;
  text-align: center;
  width: 244px;
  margin-bottom: 25px;
`;

export const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  padding-bottom: 12px;

  &::after {
    content: "";
    display: inline-block;
    min-width: 12px;
    height: 112px;
  }

  ${(props) =>
    props.isEmpty &&
    css`
      justify-content: center;
      padding-bottom: 0;

      &::after {
        display: none;
      }
    `}
`;

export const UpcomingContainer = styled.li`
  background: ${buildColor("white", "100")};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
  border: 1px solid ${buildColor("blue", "100")};
  min-width: ${({ isFeatured }) => (isFeatured ? "197px" : "142px")};
  overflow: hidden;

  &:first-child {
    margin-left: 12px;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  & > a {
    width: 100%;
    display: block;
  }
`;

export const LinkContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  min-width: 142px;
  height: 112px;
  overflow: hidden;
  font-family: ${fontMedium};
  text-decoration: none;
  font-size: 12px;

  & > a {
    color: ${buildColor("blue_accent", "500")};
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 0 0 20px;
  background-color: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("blue", "100")};

  :hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const UpcomingRacesWrapper = styled.section`
  width: 100%;
  background-color: ${buildColor("white", "100")};
`;
