import { Runner } from "./types";

export const RUNNERS_MOCK: Runner[] = [
  {
    position: 1,
    saddlecloth: 1,
    name: "Justify",
    isFavorite: true,
    win: "$9.99",
    place: "$6.99",
    show: "$3.99"
  },
  {
    position: 2,
    saddlecloth: 2,
    name: "Gronokowski",
    place: "$6.99",
    show: "$3.99"
  },
  { position: 3, saddlecloth: 3, name: "Tenfold", show: "$3.99" },
  { position: 4, saddlecloth: 4, name: "Shakalakaboomboom" }
];

export const PAYOUT_HEADERS_MOCK = ["Win", "Place", "Show"];
