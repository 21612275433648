import styled, { css } from "styled-components";

export const ScrollButton = styled.button<{
  disabled: boolean;
  hasMarginRight?: boolean;
  hasMarginLeft?: boolean;
}>`
  display: flex;
  height: auto;
  align-items: center;
  ${({ theme, disabled, hasMarginLeft, hasMarginRight }) => css`
    margin-left: ${hasMarginLeft ? theme.space["space-5"] : 0};
    margin-right: ${hasMarginRight ? theme.space["space-5"] : 0};
    padding: ${theme.space["space-3"]};
    border-radius: ${theme.radii.s};
    border: 1px solid ${theme.colorTokens.component.button.tertiary.border.base};
    opacity: ${disabled ? 0.5 : 1};
    background-color: ${disabled
      ? theme.colorTokens.component.button.tertiary.background.disabled
      : theme.colorTokens.component.button.tertiary.background.base};

    &:hover {
      background-color: ${!disabled &&
      theme.colorTokens.component.button.tertiary.background.hover};
    }
  `}
`;

export const RaceListContainer = styled.div<{ hasScrollButtons: boolean }>`
  width: 100vw;
  display: flex;
  transform: translateZ(0);
  overflow: scroll hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  ${({ theme, hasScrollButtons }) => css`
    padding: 0 ${theme.space["space-2"]};
    scroll-padding-left: ${theme.space["space-2"]};
    gap: ${theme.space[hasScrollButtons ? "space-3" : "space-1"]};
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  & > a {
    scroll-snap-align: start;
  }
`;

export const ScrollButtonFloating = styled.button<{
  disabled: boolean;
  position: "start" | "end";
  isOpen: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: absolute;
  height: 32px;
  width: 32px;
  top: 50%;
  z-index: 1;
  padding: var(--space-space2, 8px);
  border: 1px solid
    var(--fd-colors-component-button-tertiary-border-base, #0070eb);
  border-radius: var(--fd-radii-border-radius-020);
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  background-color: var(
    --fd-colors-component-button-transparent-content-base,
    #ffffff
  );
  transition:
    visibility 0.3s ease-out 0.5s,
    opacity 0.3s ease-in-out 0.5s;
  ${({ disabled, position }) => css`
    ${position === "start"
      ? "left: var(--space-space5, 16px);"
      : "right: var(--space-space5, 16px);"}
    visibility: ${disabled ? "hidden" : "visible"};
    opacity: ${disabled ? 0 : 1};
  `}

  &:hover {
    background-color: var(
      --fd-colors-component-button-tertiary-background-hover
    );
  }
`;
