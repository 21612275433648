// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import type { TopTrackRace } from "@tvg/types/Track";
import pushToDataLayer, { processTrackName } from "../gtmUtils";

const parseModule = (module: string) =>
  module
    .split("_")
    .map((word) => word[0] + word.slice(1).toLowerCase())
    .join(" ")
    .replace("Az", "AZ");

export default () => {
  mediatorChannels.base.subscribe(
    "TOP_TRACKS_SCROLL_INTERACTION",
    (data: {
      type: "TOP_TRACKS_SCROLL_INTERACTION",
      payload: {
        direction: string,
        module: string,
        isGreyhound: boolean
      }
    }) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.direction"),
        gaEventLabel: undefined,
        module: parseModule(get(data, "payload.module")),
        tag: undefined,
        microApp: undefined,
        sport: get(data, "payload.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "TOP_TRACKS_FAVORITES_INTERACTION",
    (data: {
      payload: {
        trackName: string,
        isAdding: boolean,
        module: mixed,
        isGreyhound: boolean
      }
    }) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: data.payload.isAdding ? "select" : "unselect",
        gaEventLabel: "favorite",
        module: parseModule(get(data, "payload.module", "Tracks")),
        tag: data.payload.trackName,
        microApp: undefined,
        sport: get(data, "payload.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "TOP_TRACKS:GO_TO_RACE",
    (data: {
      type: "TOP_TRACKS:GO_TO_RACE",
      payload: {
        url: string,
        trackName: string,
        race: TopTrackRace,
        flags: boolean,
        isFavorite: boolean,
        module: string,
        isGreyhound: boolean
      }
    }) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: `${processTrackName(
          get(data, "payload.trackName")
        )} - R${get(data, "payload.race.number")} - ${get(
          data,
          "payload.race.mtp"
        )}`,
        module: parseModule(get(data, "payload.module")),
        tag: `${get(data, "payload.flags")} - ${
          get(data, "payload.isFavorite", false) ? "favorite" : "no favorite"
        }`,
        menu: undefined,
        sport: get(data, "payload.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing",
        destinationUrl: get(data, "payload.url", "-")
      });
    }
  );
};
