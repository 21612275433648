// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

export const TalentPickFilterWrapper = styled.ul`
  overflow-x: scroll;
  display: flex;
  padding-bottom: 8px;
  list-style: none;
  ${({ device }) =>
    device !== "mobile"
      ? css`
          padding-top: 8px;
          background-color: ${buildColor("blue", "800")};

          & > li:first-child {
            margin-left: auto;
          }

          & > li:last-child {
            margin-right: auto;
          }
        `
      : css`
          background-color: ${buildColor("blue", "700")};
        `}
`;

export const AlertButton = styled.button`
  width: 72px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  outline: none;
  border-right: 1px solid ${buildColor("white", "20")};
  ${({ device }) =>
    device === "tablet" &&
    css`
      margin-left: auto;
    `};
`;

export const IconHolder = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${buildColor("white", "20")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertButtonDescription = styled.span`
  padding-top: 3px;
  font-size: 10px;
  color: ${buildColor("blue", "100")};
  text-align: center;
  line-height: 11px;
`;

export const TalentFilterSection = styled.section`
  width: 100%;
  position: sticky;
  z-index: 20;
  left: 0;
  top: 56px;
`;

export const EmptyStateMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-right: auto;
`;

export const Message = styled.span`
  color: ${buildColor("white", "100")};
  font-size: 12px;
  text-align: left;
  ${({ device }) =>
    device !== "mobile"
      ? css`
          width: 196px;
          margin: 18px 16px 18px 70px;
        `
      : css`
          margin: 18px 16px 18px 20px;
        `}
`;

export const ArrowIcon = styled(Icon)`
  position: absolute;
  left: ${({ device }) => (device !== "mobile" ? "-25px" : "-65px")};

  && {
    width: 100px;
  }
`;
