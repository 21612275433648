// @flow
import { noop } from "lodash";
import TracksAZTemplate from "@tvg/atomic-ui/_templates/TracksAZ";
import type { TrackComponentBehavior } from "./types";
import GraphQuery from "../graphql/queries/TracksAZQuery";
import RDAQuery from "../graphql/queries/TracksAZRDAQuery";
import GraphOptions from "../graphql/tracks-az-options.graph";

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

type QueryTypes = {
  isAccountCompliant: boolean,
  shouldUpdate: boolean
};

export const skipQuery = ({ isAccountCompliant, shouldUpdate }: QueryTypes) =>
  isAccountCompliant || !shouldUpdate;

export const skipRDAQuery = ({
  isAccountCompliant,
  shouldUpdate,
  accountId
}: QueryTypes & { accountId: string }) =>
  !isAccountCompliant || !shouldUpdate || !accountId;

const tracksAZ: TrackComponentBehavior = {
  template: TracksAZTemplate,
  query: GraphQuery,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: false,
  onlyFetchResultedTracks: false,
  renderTracksLetters: true,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps
};

export default tracksAZ;
