// @flow

import React, { PureComponent, Fragment } from "react";
import type { Promo } from "@tvg/types/Promos";
import { noop, isNil } from "lodash";
import {
  formatThreeLetterMonthDayDate,
  formatDayDate,
  formatPastRaceDate
} from "@tvg/formatter/dates";
import type { TernaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import {
  PromoLink,
  PromoTitle,
  PromoDescription,
  PromoLocation,
  PromoAddress,
  ArrowContainer,
  PromosDates,
  PromoItemContainer,
  PromoWrapper,
  PromoLinkWrapper,
  OptinWrapper
} from "./styled-components";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { optedin, arrowRight } from "../../_static/Icons/icons";
import Tag from "../../_atom/Tag";

type Props = {
  promosWidgetItem: Promo,
  onPromoItemClick: TernaryFn<Event, string, string, void>,
  qaLabelPrefix: string,
  showDescription: boolean,
  device: Device,
  hasLargeWidth?: boolean
};

export default class PromosWidgetItem extends PureComponent<Props> {
  static defaultProps = {
    promosWidgetItem: {
      name: "",
      title: "",
      description: "",
      startDate: "",
      endDate: null,
      optedIn: false,
      onClick: noop,
      path: "",
      state: "",
      country: "",
      city: "",
      qaLabelPrefix: ""
    },
    showDescription: false,
    device: "mobile",
    hasLargeWidth: false
  };

  processDate = (date: string): string =>
    `${formatThreeLetterMonthDayDate(date)} ${formatDayDate(date)}`;

  processTitle = (title: string) =>
    !this.props.hasLargeWidth && title.length > 40
      ? title.substr(0, 37).concat("...")
      : title;

  renderPromoDates = () => (
    <PromosDates data-qa-label="promoDates">
      <time
        data-qa-label={`${this.props.qaLabelPrefix}startDate`}
        itemProp="startDate"
        dateTime={formatPastRaceDate(this.props.promosWidgetItem.startDate)}
      >
        {this.processDate(this.props.promosWidgetItem.startDate)}
      </time>
      {!isNil(this.props.promosWidgetItem.endDate) && (
        <Fragment>
          <span data-qa-label={`${this.props.qaLabelPrefix}dateSeparator`}>
            {" "}
            -{" "}
          </span>
          <time
            data-qa-label={`${this.props.qaLabelPrefix}endDate`}
            itemProp="endDate"
            dateTime={formatPastRaceDate(
              this.props.promosWidgetItem.endDate || ""
            )}
          >
            {
              // $FlowFixMe
              this.processDate(this.props.promosWidgetItem.endDate)
            }
          </time>
        </Fragment>
      )}
    </PromosDates>
  );

  renderPromoDescription = () => (
    <Fragment>
      <PromoTitle
        data-qa-label={`${this.props.qaLabelPrefix}promoTitle`}
        hasLargeWidth={this.props.hasLargeWidth}
        itemProp="name"
      >
        {this.processTitle(this.props.promosWidgetItem.title)}
      </PromoTitle>
      {this.props.showDescription && (
        <PromoDescription
          data-qa-label={`${this.props.qaLabelPrefix}promoDescription`}
          hasLargeWidth={this.props.hasLargeWidth}
          itemProp="description"
        >
          {this.processTitle(this.props.promosWidgetItem.description)}
        </PromoDescription>
      )}
    </Fragment>
  );

  renderPromoLocation = () => (
    <PromoLocation
      itemProp="location"
      itemType="http://schema.org/Place"
      itemScope
    >
      <span itemProp="name">TVG</span>
      <PromoAddress
        itemProp="address"
        itemType="http://schema.org/PostalAddress"
        itemScope
      >
        <span itemProp="addressLocality">
          {this.props.promosWidgetItem.city || "Los Angeles"}
        </span>
        <span itemProp="addressRegion">
          {this.props.promosWidgetItem.state || "CA"}
        </span>
      </PromoAddress>
    </PromoLocation>
  );

  renderOptedInTag = () =>
    this.props.promosWidgetItem.optedIn && (
      <OptinWrapper>
        <Tag
          name="Opted in"
          tagType="OptedInTag"
          qaLabel={`${this.props.qaLabelPrefix}optedInTag`}
          icon={optedin}
          isCollapsed={this.props.device === "mobile"}
        />
      </OptinWrapper>
    );

  render() {
    return (
      <PromoItemContainer
        data-qa-label={`${this.props.qaLabelPrefix}container`}
        itemType="http://schema.org/Event"
        itemScope
      >
        <PromoWrapper data-qa-label={`${this.props.qaLabelPrefix}wrapper`}>
          <PromoLink
            data-qa-label={`${this.props.qaLabelPrefix}promoLink`}
            itemProp="url"
            to={this.props.promosWidgetItem.path || "/"}
            onClick={(e: Event) =>
              this.props.onPromoItemClick(
                e,
                this.props.promosWidgetItem.path,
                this.props.promosWidgetItem.title
              )
            }
          >
            <PromoLinkWrapper>
              {this.renderPromoDescription()}
              {this.renderPromoLocation()}
              {this.renderPromoDates()}
            </PromoLinkWrapper>
            {this.renderOptedInTag()}
            <ArrowContainer>
              <Icon
                qaLabel={`${this.props.qaLabelPrefix}arrowIcon`}
                icon={arrowRight}
                size={16}
                color={buildColor("blue", "200")}
              />
            </ArrowContainer>
          </PromoLink>
        </PromoWrapper>
      </PromoItemContainer>
    );
  }
}
