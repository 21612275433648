// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type TrackClickType = {
  type: string,
  payload: {
    isOpen: boolean,
    trackName: string,
    tag: string,
    module: mixed
  }
};

type RaceClickType = {
  type: string,
  payload: {
    trackName: string,
    raceNumber: string,
    mtp: number,
    menu: string,
    module: string,
    runnerType: string,
    tag: string,
    url: string
  }
};

type filterGTMData = {
  type: string,
  payload: {
    filter: string,
    numRacesUpNext: ?number,
    numRacesResultUpNext: ?number,
    numRacesFavorites: ?number,
    numRacesResultFavorites: ?number,
    numRacesAZ: ?number,
    numRacesResultAZ: ?number,
    isSelecting: boolean
  }
};

const parseModule = (module: string) =>
  module
    .split("_")
    .map((word) => word[0] + word.slice(1).toLowerCase())
    .join(" ")
    .replace("Az", "AZ");

export default () => {
  mediatorChannels.base.subscribe(
    "TRACKS_TRACK_CLICK",
    (data: TrackClickType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.isOpen") ? "Close" : "Open",
        gaEventLabel: get(data, "payload.trackName"),
        module: parseModule(get(data, "payload.module", "Tracks")),
        sport: get(data, "payload.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing",
        microApp: "non-Microapp",
        tag: get(data, "payload.tag")
      });
    }
  );

  mediatorChannels.base.subscribe("RACE_NAVIGATION", (data: RaceClickType) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: `${get(data, "payload.trackName")} - R${get(
        data,
        "payload.raceNumber"
      )}${get(data, "payload.mtp")}`,
      menu: get(data, "payload.menu"),
      module: parseModule(get(data, "payload.module")),
      sport: `${get(data, "payload.runnerType")} Racing`,
      microApp: "non-Microapp",
      tag: get(data, "payload.tag"),
      destinationUrl: get(data, "payload.url")
    });
  });

  mediatorChannels.base.subscribe(
    "TRACKS_FILTER_CLICK",
    (data: filterGTMData) => {
      pushToDataLayer({
        event: "filter",
        gaEventCategory: "Filters",
        gaEventAction: get(data, "payload.isSelecting")
          ? "Select Filter"
          : "Deselect Filter",
        gaEventLabel: get(data, "payload.filter"),
        gaEventValue: undefined,
        module: "Filter",
        microApp: "non-Microapp",
        upNextTracks:
          get(data, "payload.numRacesUpNext") &&
          get(data, "payload.numRacesResultUpNext")
            ? `${get(data, "payload.numRacesUpNext")} - ${get(
                data,
                "payload.numRacesResultUpNext"
              )}`
            : "undefined",
        favTracks:
          data.payload.numRacesFavorites && data.payload.numRacesResultFavorites
            ? `${get(data, "payload.numRacesFavorites")} - ${get(
                data,
                "payload.numRacesResultFavorites"
              )}`
            : "undefined",
        atozTracks:
          data.payload.numRacesAZ && data.payload.numRacesResultAZ
            ? `${get(data, "payload.numRacesAZ")} - ${get(
                data,
                "payload.numRacesResultAZ"
              )}`
            : "undefined"
      });
    }
  );
};
