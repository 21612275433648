// @flow
import { noop } from "lodash";
import FavoriteTracksTemplate from "@tvg/atomic-ui/_templates/FavoriteTracks";
import type { TrackComponentBehavior } from "./types";
import RDAQuery from "../graphql/queries/TracksFavoritesRDAQuery";
import GraphOptions from "../graphql/resulted-tracks-favorites-options.graph";

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

export const skipQuery = () => true;

export const skipRDAQuery = () => false;

const resultedTracksFavorite: TrackComponentBehavior = {
  template: FavoriteTracksTemplate,
  query: null,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: true,
  onlyFetchResultedTracks: true,
  renderTracksLetters: false,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps
};

export default resultedTracksFavorite;
