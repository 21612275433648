import { gql } from "@apollo/client";
import PastRacesFragment from "../fragments/PastRaces";
import BettingInterestsFragment from "../fragments/PastRacesBettingInterests";
import ResultsFragment from "../fragments/PastRacesResults";

export const GET_PAST_RACES_QUERY = gql`
  query getPastRaces(
    $filterBy: PastRaceListFilter
    $wagerProfile: String!
    $byDate: Boolean!
    $byDateTrack: Boolean!
    $date: String
    $trackCode: String
    $byDateTrackNumber: Boolean!
    $raceNumber: String
    $byRunnerId: Boolean!
    $runnerId: String
  ) {
    pastRacesByDate: pastRaces(
      filter: $filterBy
      profile: $wagerProfile
      date: $date
      sort: { byPostTime: DESC }
    ) @include(if: $byDate) {
      ...pastRacesFragment
    }
    pastRacesByDateAndTrack: pastRaces(
      filter: $filterBy
      profile: $wagerProfile
      date: $date
      trackCode: $trackCode
      sort: { byPostTime: DESC }
    ) @include(if: $byDateTrack) {
      ...pastRacesFragment
    }
    pastRaceByDateTrackAndNumber: pastRaces(
      filter: $filterBy
      profile: $wagerProfile
      date: $date
      trackCode: $trackCode
      raceNumber: $raceNumber
      sort: { byPostTime: DESC }
    ) @include(if: $byDateTrackNumber) {
      ...pastRacesFragment
      ...bettingInterestsFragment
      ...resultsFragment
    }
    pastRacesByHorseId: pastRaces(
      filter: $filterBy
      profile: $wagerProfile
      sort: { byPostTime: DESC }
      entityRunnerId: $runnerId
    ) @include(if: $byRunnerId) {
      ...pastRacesFragment
    }
  }
  ${PastRacesFragment.entry}
  ${BettingInterestsFragment.entry}
  ${ResultsFragment.entry}
`;
