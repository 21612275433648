// @flow

import type { OperationComponent } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { get, orderBy } from "lodash";
import type { WagerProfile } from "@tvg/types/User";
import type { TopPool } from "@tvg/types/Pool";
import { getPortByBrand } from "@tvg/utils/generalUtils";

type ApolloProps = {
  wagerProfile: WagerProfile,
  shouldUpdate: boolean
};

const conf = tvgConf();

const POLL_INTERVAL = 60000;

const removeFalsePinned = (topPools: TopPool[]) =>
  topPools.map((pool) =>
    get(pool, "race.highlighted.pinnedOrder", 0) === 0
      ? {
          ...pool,
          race: {
            ...pool.race,
            highlighted: { ...pool.race.highlighted, pinnedOrder: null }
          }
        }
      : pool
  );

const getPools = (topPools: TopPool[]) =>
  orderBy(
    removeFalsePinned(topPools),
    [(pool) => get(pool, "race.highlighted.pinnedOrder", null), "amount"],
    ["asc", "desc"]
  );

export default {
  skip: (props: ApolloProps) => !get(props, "shouldUpdate"),
  options: (props: ApolloProps) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      ...conf.graphContext()
    };

    return {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({
    pools: getPools(get(result, "data.carryOverPools", [])),
    isLoading: result.data.loading
  })
};
