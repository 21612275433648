import type { Actions } from "../actions/tracks";

export type State = {
  currentReplay: string | null;
  openTracks: Array<string>;
};

export const initialState = {
  currentReplay: null,
  openTracks: []
};
export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: Actions = {} as Actions
): State {
  switch (action.type) {
    case "TOGGLE_TRACK_ROW": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    case "SET_CURRENT_REPLAY_VIDEO": {
      return {
        ...state,
        currentReplay: action.payload.currentReplay
      };
    }
    default: {
      return state;
    }
  }
}
