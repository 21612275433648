// @flow

// Absolute Imports

import { get, has, differenceBy, sortBy } from "lodash";
import tvgConf from "@tvg/conf";
import { type OperationComponent } from "@apollo/client";
import type {
  UpcomingGraphRace,
  UpcomingGraphRaces,
  UpcomingGraphRacesResponse
} from "@tvg/types/Race";
import { getPortByBrand } from "@tvg/utils/generalUtils";
// Relative Imports

import type { Props } from "../types";

// Types

type QueryVariables = {
  wagerProfile: string,
  product: string,
  device: string,
  brand: string,
  isLogged: boolean,
  accountId: string,
  rdaClient: mixed,
  trackFilters: {
    allTrackClasses: boolean,
    raceTypes?: string[],
    includeCountries?: string[],
    excludeCountries?: string[]
  },
  racesFilter: {
    allRaceClasses: boolean,
    typeCode?: string[],
    includeCountries?: string[],
    excludeCountries?: string[]
  }
};

// Constants
const UPCOMING_RACES_LIMIT = 20;

// Aux Functions

const sortByMTP = (parsedRaces: UpcomingGraphRaces): UpcomingGraphRaces =>
  sortBy(parsedRaces, ["mtp", "trackName"]);

const filterAndFillRaces = (
  filteredRaces: UpcomingGraphRaces,
  extraRaces: UpcomingGraphRaces
): UpcomingGraphRaces => {
  const racesToFill = UPCOMING_RACES_LIMIT - filteredRaces.length;
  const racesToAdd = differenceBy(extraRaces, filteredRaces, "raceId").slice(
    0,
    racesToFill
  );
  return filteredRaces.concat(racesToAdd).slice(0, UPCOMING_RACES_LIMIT);
};

const parseRaces = (racesToParse: UpcomingGraphRaces): UpcomingGraphRaces =>
  racesToParse.reduce(
    (
      accumulatedRaces: UpcomingGraphRaces,
      race: UpcomingGraphRace & { races: UpcomingGraphRace }
    ): UpcomingGraphRaces => {
      let raceToParse = null;

      if (race && has(race, "races") && race.races !== null) {
        raceToParse = race.races[0];
      } else if (race && !has(race, "races")) {
        raceToParse = race;
      }

      raceToParse = raceToParse
        ? {
            ...raceToParse,
            country: get(raceToParse, "location.country", "")
          }
        : raceToParse;

      if (
        raceToParse &&
        raceToParse.promos &&
        get(raceToParse, "promos.length", 0) > 1
      ) {
        // put isAboveTheLine promos first in the array
        const sortedPromos = [...raceToParse.promos].sort(
          (a, b) => +b.isAboveTheLine - +a.isAboveTheLine
        );

        raceToParse = { ...raceToParse, promos: sortedPromos };
      }

      return raceToParse
        ? accumulatedRaces.concat([raceToParse])
        : accumulatedRaces;
    },
    []
  );

const getProps = (result: UpcomingGraphRacesResponse) => {
  let props = {};
  const isLogged = get(result, "ownProps.isLogged", false);
  const upcomingRacesLength = get(result.data, "upcomingRaces.length");

  let races = [];
  if (upcomingRacesLength) {
    const upcomingRaces = get(result, "data.upcomingRaces");

    if (isLogged) {
      races = sortByMTP(
        parseRaces(upcomingRaces)
          .slice(0, UPCOMING_RACES_LIMIT)
          .map((race) => ({ ...race, betsCount: get(race, "betsCount.count") }))
      );
    } else {
      const extraRaces = get(result, "data.extraRaces");
      const parsedRaces = sortByMTP(parseRaces(upcomingRaces));
      const extraParsedRaces =
        upcomingRacesLength < UPCOMING_RACES_LIMIT && parseRaces(extraRaces);

      races =
        upcomingRacesLength < UPCOMING_RACES_LIMIT
          ? sortByMTP(filterAndFillRaces(parsedRaces, extraParsedRaces))
          : parsedRaces.slice(0, UPCOMING_RACES_LIMIT);
    }

    props = { ...props, races, isLoading: result.data.loading };
  }

  return props;
};

const buildQueryVariables = (props: Props) => {
  const queryVariables: QueryVariables = {
    wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
    ...tvgConf().graphContext(),
    accountId: get(props, "accountId"),
    trackRacePagination: {
      current: 0,
      results: 1
    },
    pagination: {
      current: 0,
      results: UPCOMING_RACES_LIMIT
    },
    racesSort: {
      byMTP: "ASC"
    },
    racesFilter: {
      status: ["IC", "O"],
      allRaceClasses: true
    },
    trackFilters: {
      raceStatus: ["IC", "O"],
      allTrackClasses: true
    },
    wagerHistoryFilter: {
      betStatus: "ACTIVE"
    }
  };

  if (props.raceTypeFilters.length && props.parentPage === "tracks") {
    queryVariables.trackFilters.raceTypes = props.raceTypeFilters;
    queryVariables.racesFilter.typeCode = props.raceTypeFilters;
  }

  if (props.regionFilters.length && props.parentPage === "tracks") {
    if (
      props.regionFilters.indexOf("USA") >= 0 &&
      props.regionFilters.indexOf("INT") < 0
    ) {
      queryVariables.trackFilters.includeCountries = ["USA"];
      queryVariables.racesFilter.includeCountries = ["USA"];
    }

    if (
      props.regionFilters.indexOf("USA") < 0 &&
      props.regionFilters.indexOf("INT") >= 0 &&
      props.parentPage === "tracks"
    ) {
      queryVariables.trackFilters.excludeCountries = ["USA"];
      queryVariables.racesFilter.excludeCountries = ["USA"];
    }
  }

  return queryVariables;
};

export default {
  options: (props: Props) => ({
    client:
      get(props, "isAccountCompliant", false) &&
      get(props, "useRDAService", false)
        ? get(props, "rdaClient", null)
        : null,
    fetchPolicy: "cache-and-network",
    ssr: true,
    variables: buildQueryVariables(props)
  }),

  props: (result: OperationComponent<Response>) => {
    let hasError = false;

    if (get(result, "data.error", undefined) !== undefined) {
      hasError = true;
    }
    return {
      ...getProps(result),
      refetchQuery: result.data.refetch,
      isLoading: result.data.loading && !get(result, "data.upcomingRaces"),
      hasError
    };
  }
};
