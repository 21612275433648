// @flow
import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { isTvg5 } from "@tvg/utils/generalUtils";
import NumberFormat from "react-number-format";
import { get, noop } from "lodash";
import { formatDateWithTextDayFirst } from "@tvg/formatter/dates";
import type { Device } from "@tvg/types/Device";
import type { PreviousWinnerWRCResponse } from "@tvg/types/PreviousWinners";
import type { NullaryFn, UnaryFn, TernaryFn } from "@tvg/types/Functional";
import buildRaceUrl from "@tvg/formatter/url";
import {
  handleTouchMove,
  handleTouchStart,
  evaluateReachingEdge
} from "@tvg/utils/swipeEventHandlers";

import {
  Container,
  Title,
  UpperSection,
  WinnersList,
  Winner,
  TopSection,
  CatchPhrase,
  RunnerName,
  WinValue,
  BetTrackDate,
  BottomSection,
  TrackName,
  LinkContainer,
  LinkContainerTVG4,
  IconContainer,
  ArrowIconContainer,
  LastElementContainer,
  LastElement
} from "./styled-components";
import MTP from "../../_atom/MTP";
import ModalV2 from "../ModalV2";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { arrowRight, arrowForward } from "../../_static/Icons/icons";
import QuickLinksPattern from "../../_static/Patterns";

type Props = {
  device: Device,
  winners: [
    PreviousWinnerWRCResponse[],
    PreviousWinnerWRCResponse[],
    PreviousWinnerWRCResponse[]
  ],
  isDesktop: boolean,
  title: string,
  catchPhrase: string,
  modalTitle: string,
  isLoading: boolean,
  onSelection: TernaryFn<
    PreviousWinnerWRCResponse,
    string,
    ?SyntheticKeyboardEvent<*>,
    void
  >,
  /**
   * Callback when the previous winners container is being scrolled by swiping
   */
  onContainerSwipe: UnaryFn<string, void>,
  onSeeAllClick: NullaryFn<void>,
  isMTPNewRules: boolean
};

type State = {
  isModalOpen: boolean
};

export const setBetType = (betType: string) => {
  switch (betType.trim()) {
    case "WN":
      return "Win";
    case "PL":
      return "Place";
    case "SH":
      return "Show";
    default:
      return "";
  }
};

export default class PreviousWinners extends PureComponent<Props, State> {
  static defaultProps = {
    device: "mobile",
    winners: [],
    isDesktop: false,
    title: "Your Previous Winners",
    catchPhrase: "Your previous winner running again!",
    modalTitle: "Previous Winners Running Today",
    isLoading: true,
    onSelection: noop,
    onContainerSwipe: noop,
    onSeeAllClick: noop,
    isMTPNewRules: false
  };

  constructor(props: Props) {
    super(props);

    this.state = { isModalOpen: false };
  }

  onModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  prepareRaceUrl = (winner: Winner, runner?: number) => {
    let url = buildRaceUrl(
      winner.currentRace.trackCode,
      winner.currentRace.trackName,
      +winner.currentRace.raceNumber,
      false
    );

    if (isTvg5()) {
      url = url.concat("&betType=10");
    }

    return runner ? `${url}&selectedRunner=${runner}` : url;
  };

  handleSwipeGTMEvents = (e: SyntheticTouchEvent<*>) => {
    let swipeDirection = handleTouchMove(e);
    const isLivingOnTheEdge = evaluateReachingEdge(e);

    swipeDirection = isLivingOnTheEdge ? 0 : swipeDirection;
    if (swipeDirection !== 0) {
      this.props.onContainerSwipe(swipeDirection > 0 ? "right" : "left");
    }
  };

  renderWinners = (
    hasBiggestWinner: boolean,
    maxCardsWithoutBiggestWinner: number,
    isModal?: boolean
  ) => {
    const biggestWinner = this.props.winners[0];
    const otherWinners = !isModal
      ? this.props.winners[1].slice(0, maxCardsWithoutBiggestWinner)
      : this.props.winners[2];

    const winners =
      biggestWinner.length > 0 && !isModal
        ? biggestWinner.concat(otherWinners)
        : otherWinners;
    const uniqueWinner = winners.length === 1;

    return winners.map((winner, index) => {
      const isBiggestWinner = hasBiggestWinner && index === 0;
      const notIsBiggestWinner =
        !hasBiggestWinner || (hasBiggestWinner && index > 0);
      return (
        <Winner
          isBiggestWinner={isBiggestWinner}
          hasBiggestWinner={hasBiggestWinner}
          isDesktop={this.props.isDesktop || isModal}
          uniqueWinner={uniqueWinner}
          key={winner.currentRace.raceId}
          data-qa-label={`previousWinner-raceId-${winner.currentRace.raceId}`}
        >
          <Link
            data-qa-label="link"
            to={this.prepareRaceUrl(
              winner,
              winner.currentRace.bettingInterestNumber
            )}
            onClick={(event) => {
              this.props.onSelection(
                winner,
                this.prepareRaceUrl(winner),
                event
              );
            }}
          >
            <TopSection
              isDesktop={this.props.isDesktop || isModal}
              device={this.props.device}
              isBiggestWinner={isBiggestWinner}
              uniqueWinner={uniqueWinner}
            >
              {isBiggestWinner && (
                <QuickLinksPattern
                  mainColor={buildColor("green", "500")}
                  index={1000}
                  qaLabel="svg"
                />
              )}
              {isBiggestWinner && (
                <CatchPhrase data-qa-label="catchPhrase">
                  {this.props.catchPhrase}
                </CatchPhrase>
              )}
              {notIsBiggestWinner && (
                <WinValue data-qa-label="winAmount">
                  <NumberFormat
                    value={winner.wager.winAmount}
                    prefix="$"
                    thousandSeparator
                    isNumericString
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </WinValue>
              )}
              <RunnerName
                isBiggestWinner={isBiggestWinner}
                data-qa-label="horseName"
              >
                {winner.entityName}
              </RunnerName>
              {isBiggestWinner && (
                <WinValue
                  isBiggestWinner={isBiggestWinner}
                  data-qa-label="winAmount"
                >
                  <NumberFormat
                    value={winner.wager.winAmount}
                    prefix="$"
                    thousandSeparator
                    isNumericString
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </WinValue>
              )}
              <BetTrackDate
                isBiggestWinner={isBiggestWinner}
                data-qa-label="pastTrackInfo"
              >
                {setBetType(winner.wager.betType)} bet at{" "}
                {winner.pastRace.trackCode} R{winner.pastRace.raceNumber},{" "}
                {formatDateWithTextDayFirst(winner.pastRace.raceDate)}
              </BetTrackDate>
            </TopSection>
            <BottomSection
              isBiggestWinner={isBiggestWinner}
              hasBiggestWinner={hasBiggestWinner}
              isDesktop={this.props.isDesktop || isModal}
            >
              <MTP
                isPlainMTP
                isOnBiggestWinner={isBiggestWinner}
                mtp={winner.currentRace.mtp}
                postTime={winner.currentRace.postTime}
                isMTPNewRules={this.props.isMTPNewRules}
              />
              <TrackName
                isBiggestWinner={isBiggestWinner}
                data-qa-label="trackName"
              >
                {winner.currentRace.trackName} R{winner.currentRace.raceNumber}
              </TrackName>
              <ArrowIconContainer
                isDesktop={this.props.isDesktop}
                isBiggestWinner={isBiggestWinner}
              >
                <Icon
                  icon={arrowRight}
                  size={16}
                  color={
                    isBiggestWinner
                      ? buildColor("white", "100")
                      : buildColor("blue", "200")
                  }
                />
              </ArrowIconContainer>
            </BottomSection>
          </Link>
        </Winner>
      );
    });
  };

  renderLastElement = (hasBiggestWinner: boolean) => (
    <LastElementContainer
      isDesktop={this.props.isDesktop}
      hasBiggestWinner={hasBiggestWinner}
      key="PreviousWinnersLastElement"
      data-qa-label="previousWinnersLastElement"
    >
      <LastElement
        isDesktop={this.props.isDesktop}
        onClick={() => {
          this.setState({ isModalOpen: true });
          this.props.onSeeAllClick();
        }}
      >
        {!this.props.isDesktop && (
          <LinkContainer data-qa-label="previousWinnersSeeAll">
            <IconContainer data-qa-label="previousWinnersLastElement-IconContainer">
              <Icon
                icon={arrowForward}
                color={buildColor("blue_accent", "500")}
                size={16}
                qaLabel="previousWinnersLastElement-Icon"
              />
            </IconContainer>
            SEE ALL
          </LinkContainer>
        )}
      </LastElement>
    </LastElementContainer>
  );

  renderWinnersInModal = () => (
    <WinnersList
      hasBiggestWinner={false}
      hasOtherWinners
      device={this.props.device}
      isModal
    >
      {this.renderWinners(false, 0, true)}
    </WinnersList>
  );

  render() {
    const totalWinners = get(this.props, "winners[2].length", 0);
    if (totalWinners === 0) {
      return null;
    }

    const hasBiggestWinner = !!get(this.props, "winners[0].length", 0);
    const hasOtherWinners = !!get(this.props, "winners[1].length", 0);

    const maxCardsWithoutBiggestWinner = this.props.isDesktop ? 3 : 4;
    const showSeeAllElement =
      totalWinners - maxCardsWithoutBiggestWinner > (hasBiggestWinner ? 1 : 0);

    const modalProps = {
      title: this.props.modalTitle,
      animation: this.props.device === "mobile" ? "bottom" : "fade",
      isOpen: this.state.isModalOpen,
      onClose: this.onModalClose,
      qaLabel: "modal-previouswinners",
      isFullWidth: this.props.device === "mobile",
      isFluidWidth: this.props.device !== "mobile",
      isFullHeight: false
    };

    return (
      <Container
        isDesktop={this.props.isDesktop}
        data-qa-label="previousWinnersContainer"
      >
        <Fragment>
          <UpperSection
            isDesktop={this.props.isDesktop}
            hasBiggestWinner={hasBiggestWinner}
          >
            <Title
              biggerTitle={this.props.isDesktop}
              data-qa-label="previousWinnersTitle"
            >
              {this.props.title}
            </Title>
            {this.props.isDesktop && showSeeAllElement && (
              <LinkContainerTVG4
                onClick={() => {
                  this.setState({
                    isModalOpen: true
                  });
                  this.props.onSeeAllClick();
                }}
                data-qa-label="previousWinnersSeeAll"
              >
                See all
              </LinkContainerTVG4>
            )}
          </UpperSection>
          <WinnersList
            hasBiggestWinner={hasBiggestWinner}
            hasOtherWinners={hasOtherWinners}
            isDesktop={this.props.isDesktop}
            onTouchStart={(e: SyntheticTouchEvent<*>) => handleTouchStart(e)}
            onTouchMove={(e: SyntheticTouchEvent<*>) =>
              this.handleSwipeGTMEvents(e)
            }
            data-qa-label="previousWinners-List"
          >
            {this.renderWinners(
              hasBiggestWinner,
              maxCardsWithoutBiggestWinner,
              false
            )}
            {!this.props.isDesktop &&
              showSeeAllElement &&
              this.renderLastElement(hasBiggestWinner)}
          </WinnersList>
          <ModalV2 {...modalProps}>
            {/* istanbul ignore next */ () => this.renderWinnersInModal()}
          </ModalV2>
        </Fragment>
      </Container>
    );
  }
}
