// @flow

import React, { PureComponent } from "react";
import { noop } from "lodash";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { add, tick, key } from "../../_static/Icons/icons";

import {
  Selection,
  Circle,
  CircleChecked,
  NumberIcon,
  IconsWrapper
} from "./styled-components";

export type SelectionType = "default" | "key" | "number";

type Props = {
  /**
   * Width of SelectionButton
   */
  width: string,
  /**
   * Height of SelectionButton
   */
  height: string,
  /**
   * Selection's Type
   */
  selectionType: SelectionType,
  /**
   * Selection's number
   */
  number: number,
  /**
   * isChecked state of SelectionButton
   */
  isChecked: boolean,
  /**
   * Scratched state of SelectionButton
   */
  isScratched: boolean,
  /**
   * Callback function after click on SelectionButton
   */
  onChange: () => mixed,
  /**
   * has Hover on button
   */
  hasHover: boolean,
  /**
   * Redirect to racecard, means it is on top races
   */
  redirectToRacecard: boolean
};

const onChangeFunc = (
  e: SyntheticEvent<HTMLButtonElement>,
  isScratched: boolean,
  onChange: () => mixed,
  redirectToRacecard: boolean
): void => {
  if (!redirectToRacecard) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (!isScratched) {
    onChange();
  }
};

const getIcon = (
  selectionType: SelectionType,
  isChecked: boolean,
  color: string
) => {
  switch (selectionType) {
    case "number":
      return (
        <NumberIcon
          icon={!isChecked ? add : tick}
          color={color}
          size={10}
          checked={isChecked}
        />
      );
    case "key":
      return <Icon icon={key} color={color} />;
    default:
      return <Icon icon={!isChecked ? add : tick} color={color} />;
  }
};

const getSelectionButtonQAPrefix = (
  isChecked: boolean,
  isScratched: boolean,
  selectionType: string
): ?string => {
  if (isScratched) {
    return "scratched";
  }

  if (selectionType === "key") {
    if (isChecked) {
      return "key-checked";
    }
    return "key";
  }

  if (isChecked) {
    return "checked";
  }

  return null;
};

export default class SelectionButton extends PureComponent<Props> {
  static defaultProps = {
    selectionKey: "",
    width: "48px",
    height: "100%",
    selectionType: "default",
    number: 0,
    isChecked: false,
    isScratched: false,
    onChange: noop,
    hasHover: false,
    redirectToRacecard: false
  };

  render() {
    return (
      <Selection
        width={this.props.width}
        height={this.props.height}
        onClick={(e: SyntheticEvent<HTMLButtonElement>): void =>
          onChangeFunc(
            e,
            this.props.isScratched,
            this.props.onChange,
            this.props.redirectToRacecard
          )
        }
        qaLabel="selectionButton"
      >
        <IconsWrapper
          data-qa-label={getSelectionButtonQAPrefix(
            this.props.isChecked,
            this.props.isScratched,
            this.props.selectionType
          )}
        >
          {!this.props.isScratched && [
            <CircleChecked key="selected" checked={this.props.isChecked}>
              {this.props.selectionType === "number" && this.props.number}
              {getIcon(
                this.props.selectionType,
                this.props.isChecked,
                buildColor("white", "100")
              )}
            </CircleChecked>,
            <Circle
              key="unselected"
              checked={this.props.isChecked}
              hasHover={this.props.hasHover}
            >
              {this.props.selectionType === "number" && this.props.number}
              {getIcon(
                this.props.selectionType,
                this.props.isChecked,
                buildColor("green", "600")
              )}
            </Circle>
          ]}
        </IconsWrapper>
      </Selection>
    );
  }
}
