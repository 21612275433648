// @flow
import React from "react";
import type { Device } from "@tvg/types/Device";
import type { UnaryFn } from "@tvg/types/Functional";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";

import Promos from "./Promos";

type Props = {
  setModalState: UnaryFn<boolean, void>,
  isOpen: boolean,
  device: Device,
  promoTitle: string,
  promoPage: string
};

export const PromoWidgetModal = (props: Props) => {
  const baseProps = {
    title: props.promoTitle,
    titleType: "default",
    isOpen: props.isOpen,
    onClose: () => {
      props.setModalState(false);
    },
    isFullHeight: true,
    qaLabel: "modal-promos"
  };

  const parsedProps =
    props.device === "mobile"
      ? { ...baseProps, animation: "bottom" }
      : {
          ...baseProps,
          animation: "fade",
          hasOverlay: true,
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...parsedProps}>
      {() => <Promos promoPage={props.promoPage} />}
    </ModalV2>
  );
};

export default PromoWidgetModal;
