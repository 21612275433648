// @flow

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import MTP from "../../_atom/MTP";
import buildColor from "../../_static/ColorPalette";
import { fontMedium, fontNormal } from "../../_static/Typography";

const linkStyle = css`
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  padding: 11px;
  text-decoration: none;
  background-color: ${buildColor("blue_accent", "000")};
  border-radius: 4px;

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const MobileLink = styled(Link)`
  ${linkStyle};
`;

export const DesktopLink = styled(Link)`
  ${linkStyle};
  cursor: pointer;
`;

export const RaceMTP = styled(MTP)`
  flex: 0 0 44px;
`;

export const Header = styled.header`
  position: relative;
`;

export const InfoWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 ${(props) => (props.isPictureShown ? "80px" : 0)} 0 8px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 72px;
  height: auto;
  position: absolute;
  right: 12px;
  bottom: 0;
`;

export const TrackRace = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: 500;
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  ${(props) =>
    props.isFeatured &&
    css`
      color: ${buildColor("blue", "500")};
    `}
`;

export const Track = styled.span`
  font-family: ${fontMedium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Race = styled.span`
  font-family: ${fontMedium};
  text-transform: uppercase;
  margin-left: 4px;
  flex: 0 0 auto;
`;

export const Talent = styled.span`
  font-size: 14px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};
`;
