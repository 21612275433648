// @flow

import React, { PureComponent, type Node } from "react";
import { Container, LeftColumn, RightColumn } from "./styled-components";

type Props = {
  leftContent: Node,
  rightContent: Node,
  hasStackedCards: boolean,
  qaLabel: string
};

export default class HomepageDesktopContainer extends PureComponent<Props> {
  static defaultProps = {
    leftContent: null,
    rightContent: null,
    hasStackedCards: false,
    qaLabel: "HomepageDesktopContainer"
  };

  render() {
    return (
      <Container
        data-qa-label={this.props.qaLabel}
        hasStackedCards={this.props.hasStackedCards}
      >
        <LeftColumn>{this.props.leftContent}</LeftColumn>
        <RightColumn>{this.props.rightContent}</RightColumn>
      </Container>
    );
  }
}
