// @flow

import styled, { css } from "styled-components";
import { fontMedium, fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Wrapper = styled.section`
  width: 100%;
  background-color: ${buildColor("white", "100")};
  border-radius: ${(props) => (props.isDesktop ? "4px" : "0")};
`;

export const Title = styled.h1`
  height: 48px;
  display: flex;
  align-items: center;
  font-family: ${fontBold};
  font-size: ${(props) => (props.isDesktop ? "18px" : "16px")};
  color: ${buildColor("grey", "900")};
  padding: ${(props) => (props.isDesktop ? "17px 12px 4px 12px" : "12px")};
`;

export const SubTitle = styled.span`
  font-family: ${fontMedium};
  padding: 16px 12px 0;
  text-transform: uppercase;
  font-size: 12px;
  color: ${buildColor("grey", "800")};
`;

export const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  overflow: auto;
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 0 0 8px 0;
          flex-flow: column nowrap;
        `
      : css`
          flex-flow: row nowrap;
          padding: 0 12px 12px;

          &::after {
            content: "";
            padding: 6px;
          }
        `}
`;

export const PoolContainer = styled.li`
  list-style: none;
  flex: 0 0 auto;
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 0 12px;
          &:not(:last-of-type) > :first-child {
            border-bottom: 1px solid ${buildColor("blue", "000")};
          }

          &:hover {
            background: ${buildColor("blue_accent", "100")};
          }
        `
      : css`
          &:not(:first-of-type) {
            margin-left: 8px;
          }
        `}
`;
