// @flow
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import fetch from "node-fetch";
import TVGConf from "@tvg/conf";

export type GraphClient = "graph" | "fcp" | "rda";

const mergeObjectWithArrayEntry = (
  existing: [] = [],
  incoming: [],
  { mergeObjects }
) => {
  const merged: any[] = existing ? existing.slice(0) : [];
  incoming.forEach((obj, i) => {
    if (existing[i]) {
      const newvalue = { ...existing[i] };
      Object.keys(obj).forEach((entry) => {
        if (Array.isArray(obj[entry])) {
          newvalue[entry] = mergeObjectWithArrayEntry(
            existing[i][entry],
            obj[entry],
            { mergeObjects }
          );
        } else {
          newvalue[entry] = mergeObjects(existing[i][entry], obj[entry]);
        }
      });
      merged[i] = newvalue;
    } else {
      merged.push(obj);
    }
  });
  return merged;
};

const mergeArrayByField = (fieldNames: any, keyfield) => {
  return (existing: [] = [], incoming: [], { readField, mergeObjects }) => {
    const merged: any[] = existing ? existing.slice(0) : [];

    const objectFieldToIndex = Object.create(null);
    if (existing) {
      existing.forEach((obj, index) => {
        objectFieldToIndex[readField(fieldNames[keyfield], obj)] = index;
      });
    }

    incoming.forEach((obj) => {
      const field = readField(fieldNames[keyfield], obj);
      const index = objectFieldToIndex[field];
      if (typeof index === "number") {
        const newvalue = { ...existing[index] };
        Object.keys(obj).forEach((entry) => {
          if (
            Object.keys(fieldNames).some((fieldName) => fieldName === entry)
          ) {
            newvalue[entry] = mergeArrayByField(fieldNames, entry)(
              existing[index][entry],
              obj[entry],
              { readField, mergeObjects }
            );
          } else if (Array.isArray(obj[entry])) {
            newvalue[entry] = mergeObjectWithArrayEntry(
              existing[index][entry],
              obj[entry],
              { mergeObjects }
            );
          } else {
            newvalue[entry] = mergeObjects(existing[index][entry], obj[entry]);
          }
        });
        merged[index] = newvalue;
      } else {
        objectFieldToIndex[fieldNames[keyfield]] = merged.length;
        merged.push(obj);
      }
    });

    return merged;
  };
};

const getClientURI = (graphClient: GraphClient): string => {
  let uri = "";

  switch (graphClient) {
    case "fcp":
      uri = TVGConf().config().service.fcp;
      break;
    case "rda":
      uri = TVGConf().config().service.rda;
      break;
    default:
      uri = TVGConf().config().service.graph;
      break;
  }

  return uri;
};

export default {
  getClient(graphClient: GraphClient = "graph"): Client {
    switch (graphClient) {
      case "rda":
        return this.rdaClient;
      case "fcp":
        return this.fcpClient;
      default:
        return this.graphClient;
    }
  },

  setClient(client: Client, type: GraphClient): void {
    switch (type) {
      case "rda":
        this.rdaClient = client;
        break;
      case "fcp":
        this.fcpClient = client;
        break;
      default:
        this.graphClient = client;
        break;
    }
  },

  createClient(
    ssrMode: boolean = false,
    graphClient: GraphClient = "graph"
  ): Client {
    const httpLink = createHttpLink({
      fetch,
      uri: getClientURI(graphClient),
      credentials: "include",
      headers: { "x-tvg-context": TVGConf().context() }
    });

    const retryLink = new RetryLink({
      attempts: () => true,
      delay: (count: number) => {
        if (count < 25) {
          return 2000 * Math.random();
        }
        return 15000;
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

      if (networkError) {
        console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
      }
    });

    let link;
    if (typeof window === "undefined") {
      link = errorLink.concat(httpLink);
    } else {
      link = retryLink.concat(errorLink).concat(httpLink);
    }

    const cache = new InMemoryCache({
      typePolicies: {
        Race: {
          fields: {
            numRaces: {
              merge: true
            },
            status: {
              merge: true
            },
            video: {
              merge: true
            },
            promos: {
              merge(existing: [], incoming: []) {
                return incoming ? [...incoming] : null;
              }
            },
            highlighted: {
              merge: true
            },
            surface: {
              merge: true
            },
            type: {
              merge: true
            },
            raceClass: {
              merge: true
            },
            changes: {
              surface: {
                merge: true
              },
              horse: {
                merge: true
              }
            },
            track: {
              merge: true
            },
            talentPicks: {
              merge: mergeArrayByField({ main: "id" }, "main")
            },
            wagerTypes: {
              merge(existing: [], incoming: []) {
                return incoming ? [...incoming] : null;
              }
            },
            racePools: {
              merge(existing: [], incoming: []) {
                return incoming ? [...incoming] : null;
              }
            },
            runnersPools: {
              merge(existing: [], incoming: []) {
                return incoming ? [...incoming] : null;
              }
            },
            willPays: {
              type: {
                merge: true
              },
              payouts: {
                merge(existing: [], incoming: []) {
                  return incoming ? [...incoming] : null;
                }
              },
              legResults: {
                merge(existing: [], incoming: []) {
                  return incoming ? [...incoming] : null;
                }
              }
            },
            probables: {
              merge(existing: [], incoming: []) {
                return incoming ? [...incoming] : null;
              }
            },
            results: {
              runners: {
                merge(existing: [], incoming: []) {
                  return incoming ? [...incoming] : null;
                }
              },
              payoffs: {
                merge(existing: [], incoming: []) {
                  return incoming ? [...incoming] : null;
                }
              }
            },
            bettingInterests: {
              merge: mergeArrayByField(
                { main: "biNumber", runners: "runnerId" },
                "main"
              )
            }
          }
        }
      }
    });

    if (typeof window !== "undefined") {
      cache.restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle
    }

    const client = new ApolloClient({
      link,
      cache,
      addTypename: true,
      ssrMode
    });

    this.setClient(client, graphClient);

    return client;
  }
};
