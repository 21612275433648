// @flow
import { get } from "lodash";
import type { OperationComponent } from "@apollo/client";
import { getPortByBrand } from "@tvg/utils/generalUtils";

type Props = {
  wagerProfile: string,
  fcpClient: mixed,
  enablePicksTracksTabAlerts: boolean
};

const getProps = (result) => ({
  tracks: get(result, "data.tracks"),
  isLoading: result.data.loading
});

export default {
  skip: (props: Props) =>
    !get(props, "isOpen") || !props.enablePicksTracksTabAlerts,
  options: (props: Props) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({ ...getProps(result) })
};
