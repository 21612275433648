export const LABELS = {
  VIEW_RESULTS: "View Results",
  PRESS_TO_REVEAL: "Press to reveal",
  DATE_TIME: "Race date and time",
  RACE: "Race",
  MY_BETS: "My Bets",
  FAVORITE: "FAV",
  REVEAL_RESULTS: "Reveal race results",
  WATCH_REPLAY: "Watch race replay",
  RUNNERS_LIST: "List of runners",
  EMPTY_STRING: ""
};
