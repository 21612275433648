import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RaceCell, Icon } from "@tvg/design-system/web";
import buildRaceUrl from "@tvg/formatter/url";
import { Race, Promo } from "@tvg/ts-types/Race";
import {
  getIsLogged,
  getOptedInPromos as getOptedInPromosStore
} from "@urp/store-selectors/users";
import getOptedInPromos from "@tvg/utils/PromoUtils";
import {
  getActiveBets,
  getOpenTracks,
  getCurrentTabTracksPage
} from "@urp/lib-racetracks/src/redux/selectors";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { get } from "lodash";
import { isTrackOpen } from "@urp/lib-racetracks/src/utils/races";
import { useRaceTracksFilter } from "@urp/lib-racetracks/src";
import Collapsible from "../Collapsible";
import CollapsibleSummary from "../CollapsibleSummary";
import { Props } from "./types";
import { LETTER_BLOCK, LIST, RACE } from "../../utils/constants";
import {
  ScrollButton,
  RaceListContainer,
  ScrollButtonFloating
} from "./styled-components";
import RaceResultCard from "../RaceResultCard";
import { useRaceScroll } from "../../hooks/useRaceScroll";

const CollapsibleWrapper = ({
  track,
  onTrackClickHandler,
  module,
  qaLabel = "",
  isResults = false,
  checkRacePromos,
  racesWithBets,
  onMyBetsClick
}: Props) => {
  const dispatch = useDispatch();
  const isUserLogged = useSelector(getIsLogged);
  const optedInPromos = useSelector(getOptedInPromosStore);
  const openTracks = useSelector(getOpenTracks);
  const activeBets = useSelector(getActiveBets);
  const currentTabTracksPage = useSelector(getCurrentTabTracksPage);
  const races = track.races as Race[] | undefined;
  const isOpen = isTrackOpen(openTracks, track.code) && !!races;
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { getCurrentActiveFilters } = useRaceTracksFilter();

  const {
    isNextEnabled,
    isPrevEnabled,
    containerRef: racesRef,
    handleScroll
  } = useRaceScroll(races, isOpen);

  const renderRaceCell = (race: Race) => {
    const promo: Promo | null = getOptedInPromos(
      race.promos || [],
      optedInPromos,
      isUserLogged
    );

    const raceUrl = buildRaceUrl(
      race.track.code,
      race.track.name,
      +race.number
    );

    const { hasPromoAvailable, isOptedInPromo } = checkRacePromos(race.promos);

    return (
      <RaceCell.Summary
        key={race.id}
        race={race}
        qaLabel={buildQaLabel([qaLabel, LETTER_BLOCK, race.id])}
        to={raceUrl}
        hasPromosAvailable={hasPromoAvailable}
        isOptedIn={isOptedInPromo}
        numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
        onClick={() =>
          sendRaceCellClickedAnalyticEvt({
            module,
            trackName: race.track.name,
            raceNumber: race.number,
            linkUrl: raceUrl,
            promoOptedIn: isOptedInPromo ? "true" : "false",
            trackHasPromos: track.hasAboveTheLinePromo,
            raceHasPromos: !!promo,
            isFDTV: race.video.onTvg || race.video.onTvg2,
            trackCountry: track.location?.country || "",
            tabActive: currentTabTracksPage,
            activeFilters: isDesktop ? getCurrentActiveFilters() : undefined
          })
        }
      />
    );
  };

  return (
    <Collapsible
      key={track.code}
      summary={
        <CollapsibleSummary
          trackCode={track.code}
          trackName={track.name}
          isResulted={track.isFinished}
          hasPromo={track.hasAboveTheLinePromo}
          qaLabel={qaLabel}
          module={module}
        />
      }
      isOpen={isOpen}
      onClick={() => onTrackClickHandler(track.code, isResults)}
      qaLabel={buildQaLabel([qaLabel, track.code])}
    >
      {isDesktop &&
        (isResults ? (
          <ScrollButtonFloating
            data-qa-label={buildQaLabel([qaLabel, "prev", "button"])}
            onClick={() => handleScroll("prev")}
            disabled={!isPrevEnabled}
            isOpen={isOpen}
            position="start"
          >
            <Icon
              name="arrowLeft"
              size="s"
              lineColor="--fd-colors-component-button-tertiary-content-base"
            />
          </ScrollButtonFloating>
        ) : (
          <ScrollButton
            data-qa-label={buildQaLabel([qaLabel, "prev", "button"])}
            onClick={() => handleScroll("prev")}
            disabled={!isPrevEnabled}
            hasMarginLeft
          >
            <Icon name="arrowLeft" size="s" />
          </ScrollButton>
        ))}

      <RaceListContainer
        ref={racesRef}
        hasScrollButtons={isDesktop}
        data-qa-label={buildQaLabel([qaLabel, RACE, LIST])}
      >
        {isOpen &&
          races?.map((race: Race) =>
            isDesktop && isResults ? (
              <RaceResultCard
                key={race.track.code + race.number}
                dispatch={dispatch}
                race={race}
                isGrouped
                betsCounter={(racesWithBets?.[race.number] as number) ?? 0}
                onMyBetsClick={() =>
                  onMyBetsClick?.({
                    trackCode: race.track.code,
                    trackName: race.track.name,
                    raceNumber: race.number
                  })
                }
              />
            ) : (
              renderRaceCell(race)
            )
          )}
      </RaceListContainer>

      {isDesktop &&
        (isResults ? (
          <ScrollButtonFloating
            data-qa-label={buildQaLabel([qaLabel, "next", "button"])}
            onClick={() => handleScroll("next")}
            disabled={!isNextEnabled}
            isOpen={isOpen}
            position="end"
          >
            <Icon
              name="arrowRight"
              size="s"
              lineColor="--fd-colors-component-button-tertiary-content-base"
            />
          </ScrollButtonFloating>
        ) : (
          <ScrollButton
            data-qa-label={buildQaLabel([qaLabel, "next", "button"])}
            onClick={() => handleScroll("next")}
            disabled={!isNextEnabled}
            hasMarginRight
          >
            <Icon name="arrowRight" size="s" />
          </ScrollButton>
        ))}
    </Collapsible>
  );
};

export default memo(CollapsibleWrapper);
